import React from 'react';
import { Price } from '../Price';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const PriceWrapper = styled('div')`
  .price {
    color: #000;
    font-family: ${theme.fonts.primary};
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 114%; /* 45.6px */
  }
  .new-price {
    color: ${theme.colors.red};
    font-family: ${theme.fonts.primary};
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 114%; /* 45.6px */
  }
  .old-price {
    color: #000;
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 114%; /* 18.24px */
    text-decoration: none;
  }
  &.mastarpris {
    .price {
      color: ${theme.colors.newOrage};
      font-family: ${theme.fonts.primary};
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 114%; /* 45.6px */
    }
  }

  .mastarpris-wrapper {
    position: relative;
    width: fit-content;
    cursor: pointer;
    :hover {
      .content-wrapper {
        transform: scale(1);
      }
    }
  }
`;

const PriceBadge = styled('div')`
  border-radius: 27px;
  width: fit-content;
  margin-bottom: 8px;
  color: #fff;
  font-family: ${theme.fonts.primary};
  font-size: 11px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  &.package-price {
    padding: 5px 15px;
    background-color: ${theme.colors.red};
    /* background-color: black; */
    color: white;
  }
  &.mastarpris {
    padding: 5px 25px 5px 15px;
    background-color: ${theme.colors.newOrage};
    color: white;
  }
  &.discounted {
    padding: 5px 15px;
    background-color: ${theme.colors.red};
    color: white;
  }
`;

const Tooltip = styled('span')`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 4px;
  right: 4px;

  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 10px;
  background: #ffffff;

  span {
    line-height: 10px;
    font-size: 10px;
    color: ${theme.colors.newOrage};
    font-weight: 600;
  }

  .content-wrapper {
    color: #000;
    transition: 0.3s;
    transform-origin: left bottom;
    transform: scale(0);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f3f3f3;
    position: absolute;
    bottom: 18px;
    left: 12px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 1;

    width: 300px;
    ${theme.below.md} {
      width: 200px;
    }
  }

  p {
    padding: 20px;
    font-size: 12px;
    line-height: 18px;
  }
`;

const OnCardPriceWrapper = styled('div')`
  .price {
    color: #000;
    font-family: ${theme.fonts.primary};
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: 114%; /* 45.6px */
  }
  .new-price {
    color: ${theme.colors.red};
    font-family: ${theme.fonts.primary};
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: 114%; /* 45.6px */
  }
  .old-price {
    color: #000;
    font-family: ${theme.fonts.primary};
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 114%; /* 18.24px */
    text-decoration: none;
    opacity: 1 !important;
    text-decoration: line-through;
    margin-top: 2px;
  }
  &.mastarpris {
    .price {
      color: ${theme.colors.newOrage};
      font-family: ${theme.fonts.primary};
      font-size: 13px;
      font-style: normal;
      font-weight: 800;
      line-height: 114%; /* 45.6px */
    }
  }

  ${theme.below.lg} {
    .price,
    .new-price,
    .old-price {
      font-size: 12px !important;
    }
  }
`;

export const PriceComponent = ({
  product,
  isPackage,
  packageProduct,
  selectedVariation,
  onCard,
}) => {
  const mastarPris = product?.customFields?.find(
    ({ key }) => key === 'Mastarpris'
  )?.boolValue;

  const discounted = product?.price?.incVat < product?.previousPrice?.incVat;
  const discount =
    100 - (product?.price?.incVat / product?.previousPrice?.incVat) * 100;

  if (onCard) {
    return (
      <OnCardPriceWrapper className={mastarPris && 'mastarpris'}>
        <Price
          previousPrice={(selectedVariation || product)?.previousPrice}
          price={(selectedVariation || product)?.price}
        />
      </OnCardPriceWrapper>
    );
  }

  return (
    <PriceWrapper className={mastarPris && 'mastarpris'}>
      {isPackage ||
        (packageProduct && (
          <PriceBadge className="package-price">PAKETPRIS</PriceBadge>
        ))}
      {mastarPris && (
        <div className="mastarpris-wrapper">
          <PriceBadge className="mastarpris">MÄSTARPRIS</PriceBadge>
          <Tooltip className={'tooltip'}>
            <span>i</span>
            <div className="content-wrapper">
              <p>
                <strong>Mästarpriser </strong>
                på Möbelmästarna är extra fördelaktiga priser som vi förhandlat
                fram för våra kunder. priserna är lägre än de rekommenderade
                priserna från leverantören men är inte rabatterade priser.
                mästarpriser är fasta och inte tidsbegränsade. Även våra
                mästarpriser kan påverkas av prisändringar och yttre faktorer.
                Skillnaden är att Mästarpriser inte är relaterade till kampanjer
                eller är att ses som ett rabatterat pris.
              </p>
            </div>
          </Tooltip>
        </div>
      )}
      {discounted && !isPackage && !packageProduct && (
        <PriceBadge className="discounted">
          {parseInt(discount)}% RABATT
        </PriceBadge>
      )}
      <Price
        previousPrice={(selectedVariation || product)?.previousPrice}
        price={(selectedVariation || product)?.price}
      />
    </PriceWrapper>
  );
};
