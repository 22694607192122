import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';

import { theme } from '../Theme';

const MaxWidthWrapper = styled(MaxWidth)`
  ${theme.below.md} {
    padding: 0;
  }
`;
const CampaingsWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Title = styled('h1')`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const Campaigns = ({ children, title = null }) => {
  return (
    <MaxWidthWrapper>
      <CampaingsWrapper>
        {title && <Title>{title}</Title>}
        {children}
      </CampaingsWrapper>
    </MaxWidthWrapper>
  );
};

export default Campaigns;
