import React from 'react';
import { styled } from 'linaria/react';

import { theme } from '../Theme';

const StyledColumn = styled('div')`
  flex: 1;
  margin-top: auto;
  margin-bottom: auto;

  font-size: 14px;
  font-family: Lato, sans-serif;
  font-weight: 300;
  letter-spacing: 0.2px;
  line-height: 1.5;

  ${theme.below.sm} {
    margin: 0 0 36px;
  }
`;

const Column = ({ children }) => <StyledColumn>{children}</StyledColumn>;
export default Column;
