import React from 'react';
import { styled } from 'linaria/react';

const TitleWrapper = styled('div')`
  width: 100%;

  h2 {
    color: ${(props) => props.foregroundcolor};
    text-align: center;
    font-size: 28px;
    font-weight: 400;
    font-family: 'Lato';
    line-height: 36px;
    margin-bottom: 1.5rem;
  }
`;

export const Title = ({ title, foreground }) => {
  return (
    <TitleWrapper
      foregroundcolor={foreground?.value ? foreground?.value : '000'}
    >
      {title?.value && <h2>{title?.value}</h2>}
    </TitleWrapper>
  );
};
