import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { Link } from 'react-router-dom';
import { ReactComponent as StoreIcon } from '../../../svg/Store.svg';
import { ReactComponent as PhoneIcon } from '../../../svg/Phone.svg';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';

import { theme } from '../../Theme';

const HeaderButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 -8px -4px;

  a + a {
    margin-left: 64px;
  }
`;

const StyledLink = styled(Link)`
  height: 53px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.white};
  padding: 8px;
  border-radius: 4px;
  text-decoration: none;

  svg {
    width: 20px;
    height: 20px;
  }

  span {
    margin-top: 5px;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    color: #333333;
  }

  &:hover {
    background: ${theme.colors.notWhite};
  }
`;

const HeaderButtons = () => {
  return (
    <>
      <HeaderButtonsWrapper>
        <StyledLink to="/butiker">
          <StoreIcon />
          <span>{t('Stores')}</span>
        </StyledLink>
        <StyledLink to="/kontakta-oss">
          <PhoneIcon />
          <span>{t('Contact')}</span>
        </StyledLink>
        <CartButton />
      </HeaderButtonsWrapper>
      <CartFlyout />
    </>
  );
};

export default HeaderButtons;
