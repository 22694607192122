import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image/';

import { theme } from '../Theme';

const CategoryLink = styled(Link)`
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 4px;

  h2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Lato';
    font-weight: 700;
    font-size: ${theme.fontSizes[7]};
    letter-spacing: 0.2px;
    color: ${theme.colors.white};

    text-transform: uppercase;

    z-index: 2;

    ${theme.below.md} {
      font-size: 20px;
    }
  }

  > div {
    transform: scale(1);
    transition-property: transform;
    transition-duration: 0.4s;
  }
  &:hover > div {
    transform: scale(1.05);
  }

  ${theme.below.sm} {
    padding-bottom: 35%;

    h2 {
      font-size: 24px;
    }
  }
`;

const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Category = ({ url, title, name, image }) => (
  <CategoryLink to={url}>
    <h2>{title || name}</h2>
    <BackgroundImage src={image} fillAvailableSpace></BackgroundImage>
  </CategoryLink>
);

export default Category;
