import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';
import MaxWidth from '../Layout/MaxWidth';
import Image from '@jetshop/ui/Image/';
import { Price } from '@jetshop/ui/Price';
import ProductLink from '@jetshop/ui/ProductLink';
import CustomBadges from '../ui/CustomBadges';

const CampaignRowWrapper = styled('section')`
  display: flex;
  flex-direction: column;
  h1 {
    font-family: ${theme.fonts.primary};
    font-size: 28px;
    line-height: 34px;
    font-weight: 500;
    text-align: center;
    margin: 16px 0;
  }
  h2 {
    font-family: ${theme.fonts.primary};
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
    text-align: center;
    margin: 0;
  }
  h3 {
    font-family: ${theme.fonts.primary};
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    margin: 0;
  }
`;

const CampaignRowItemsWrapper = styled(MaxWidth)`
  width: 100%;
  height: 50vw;
  max-height: 750px;
  background: white;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 0 auto;

  ${theme.below.lg} {
    height: 100%;
    max-height: 285vw;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .LEFT {
    [data-flight-image-children] {
      justify-content: flex-start;
    }
  }
  .CENTER {
    [data-flight-image-children] {
      justify-content: center;
    }
  }

  .RIGHT {
    [data-flight-image-children] {
      justify-content: flex-end;
    }
  }

  &.layout2 {
    .position1 {
      grid-area: 1 / 1 / 3 / 5;
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
    .position2 {
      grid-area: 3 / 1 / 5 / 5;
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
  }

  &.layout1 {
    max-height: 375px;
    .position1 {
      grid-area: 1 / 1 / 5 / 5;
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
  }
`;

const CampaignRowProductWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props?.reverted};
  ${theme.below.lg} {
    flex-direction: column;
  }

  &:hover {
    cursor: pointer;
    .light {
      background: ${theme.colors.hoverRed};
      color: #fff;
    }
    .dark {
      background: ${theme.colors.darkRed};
      color: #fff;
    }
  }
`;

const ImageWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 50%;
  > div {
    mix-blend-mode: multiply;
    width: 100%;
  }

  [data-flight-image-container] {
    width: 100% !important;
  }
  ${theme.below.lg} {
    height: 60vw !important;
    width: 100%;
  }
`;

const ProductTextWrapper = styled('div')`
  max-height: 100%;
  width: 50%;
  background: #e8e4e1;
  display: flex;
  justify-content: center;
  align-items: center;
  ${theme.below.lg} {
    width: 100%;
    padding: 1rem 2.5rem;
  }
  height: 60vw;
`;

const BottomContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.mastarpris {
    .new-price {
      color: #ef843c;
    }
  }
`;

const PriceWrapper = styled(Price)`
  .new-price {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: ${theme.colors.red};
    ${theme.below.lg} {
      font-size: 35px;
      line-height: 38px;
      margin-bottom: 0.5rem;
    }
  }
  .old-price {
    text-decoration: none;
    font-family: Lato;
    font-style: normal;
    font-weight: 200;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #000000;
    text-transform: lowercase;
  }

  .old-price::before {
    content: 'Ord pris ';
    text-transform: uppercase;
  }
`;

const CTA = styled('div')`
  text-decoration: none;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 1.5rem 0;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.1em;
  font-weight: 700;
  color: #fff;
  border-radius: 15px;
  padding: 0 2.5rem;
  &.light {
    background: ${theme.colors.darkRed};
  }
  &.dark {
    background: ${theme.colors.hoverRed};
  }
  &.small {
    height: 24px;
    width: fit-content;
    padding: 0 1.3rem;
  }
`;

const BlockContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 3rem;

  ${theme.below.md} {
    justify-content: space-evenly;
  }
  h3 {
    font-size: 22px;
    line-height: 26px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
`;

const CampaignRowProductLink = styled(ProductLink)`
  text-decoration: none;
  color: inherit;
  height: 100%;
  width: 100%;
`;

export const CampaignRowProduct = ({
  product,
  position,
  imageSrc = null,
  buttonText,
  buttonTheme,
  buttonSize,
  title,
  reverted,
}) => {
  if (!product) {
    return null;
  }
  const image =
    imageSrc?.value ??
    product?.value?.images[1]?.url ??
    product?.value?.images[0].url;

  const mastarPris = product?.value?.customFields?.find(
    ({ key }) => key === 'Mastarpris'
  )?.boolValue;

  return (
    <CampaignRowProductWrapper
      reverted={reverted?.value === true ? 'row-reverse' : 'row'}
      className={cx('position' + position?.value, 'product-wrapper')}
    >
      <ImageWrapper>
        <CampaignRowProductLink product={product?.value}>
          <Image src={image} cover={true}>
            <CustomBadges
              badges={product?.value?.badges}
              horizontalInverted={product?.value?.location}
            />
          </Image>
        </CampaignRowProductLink>
      </ImageWrapper>
      <ProductTextWrapper>
        <CampaignRowProductLink product={product?.value}>
          <BlockContent>
            {title?.value ? (
              <h3>{title?.value} </h3>
            ) : (
              <h3>{product?.value?.name}</h3>
            )}

            <BottomContent className={mastarPris && 'mastarpris'}>
              <PriceWrapper
                previousPrice={product?.value?.previousPrice}
                price={product?.value?.price}
              />
              {buttonText?.value && (
                <CTA className={cx(buttonTheme?.value, buttonSize?.value)}>
                  <span>{buttonText?.value}</span>
                </CTA>
              )}
            </BottomContent>
          </BlockContent>
        </CampaignRowProductLink>
      </ProductTextWrapper>
    </CampaignRowProductWrapper>
  );
};

export const CampaignRow = ({ title, children }) => {
  return (
    <CampaignRowWrapper>
      {title?.value && <h1>{title?.value}</h1>}
      <CampaignRowItemsWrapper className={cx('layout' + children?.length)}>
        {children}
      </CampaignRowItemsWrapper>
    </CampaignRowWrapper>
  );
};
