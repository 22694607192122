import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import PreviewRoute from '@jetshop/core/components/DynamicRoute/PreviewRoute';
import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React, { useContext } from 'react';
import { Query } from 'react-apollo';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import loadFontCss from '../fonts/loadFontCss';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import CartFlyout from './Cart/CartFlyout';
import LoadingPage from './LoadingPage';
import NotFound from './NotFoundPage';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import pageLinksQuery from './PageLinksQuery.gql';
import Store from './Store/Store';
import { theme } from './Theme';
import '../globalStyles';
import { StoreProvider, StoreContext } from './StoreHandler/StoreContext';

import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import StorePages from './StorePages/StorePages';
//Fallback slugs
import initialStoreSlugs from '../storeSlugs.json';

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />,
});

const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPageNew'),
  {
    fallback: <LoadingPage />,
  }
);

const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: <LoadingPage />,
  }
);

const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />,
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />,
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: <LoadingPage />,
});

// const LoadableStorePages = loadable(() => import('./StorePages/'), {
//   fallback: <LoadingPage />,
// });

function Shop() {
  const { storeList } = useContext(StoreContext);
  const storeSlugs =
    storeList && storeList?.length > 0
      ? storeList?.map((store) => store?.store_slug)
      : initialStoreSlugs;

  return (
    <GenericError>
      <ModalProvider>
        <PaginationProvider defaultProductsPerPage={20}>
          <Query query={pageLinksQuery}>
            {(pageLinks) => (
              <Container>
                <LoadingBar />
                <CustomFont
                  primaryFont={theme.fonts.primary}
                  injectCss={loadFontCss}
                />
                <Helmet
                  titleTemplate="%s - Möbelmästarna"
                  defaultTitle="Möbelmästarna - Möbler för hela hemmet"
                />
                <Header pageLinks={pageLinks?.data} />
                <CartFlyout />
                <Content>
                  <Switch>
                    <Route exact path="/" component={LoadableStartPage} />
                    <Route path="/search" component={LoadableSearchPage} />
                    <Route path="/butiker/:id" component={Store} />
                    <Route path="/butiker" component={StoreLocator} />
                    {storeSlugs?.map((store, index) => (
                      <Route
                        key={`store-home-${store}-${index}`} // Tilldela en unik key för varje Route
                        exact
                        path={`/${store}`}
                        component={LoadableStartPage}
                      />
                    ))}
                    {storeSlugs?.map((store, index) => (
                      <Route
                        key={`store-pages-${store}-${index}`} // Tilldela en unik key för varje Route
                        exact
                        path={`/${store}/:slug`}
                        component={StorePages}
                      />
                    ))}
                    <Route
                      path="/preview"
                      render={(props) => (
                        <PreviewRoute
                          productPage={LoadableProductPage}
                          productQuery={ProductPreviewQuery}
                          categoryQuery={CategoryPreviewQuery}
                          categoryPage={LoadableCategoryPage}
                          StartPage={LoadableStartPage}
                          {...props}
                        />
                      )}
                    />
                    <DynamicRoute
                      routeQuery={routeQuery}
                      productPage={LoadableProductPage}
                      categoryPage={LoadableCategoryPage}
                      contentPage={LoadableContentPage}
                      notFoundPage={NotFound}
                      LoadingPage={LoadingPage}
                    />
                  </Switch>
                </Content>
                <Footer pageLinks={pageLinks?.data} />
                <ModalRoot />
                <ScrollRestorationHandler
                  ignoreForRouteTypes={[
                    'sortOrderChange',
                    'filterChange',
                    'paginationChange',
                  ]}
                />
              </Container>
            )}
          </Query>
        </PaginationProvider>
      </ModalProvider>
    </GenericError>
  );
}

const ShopWithStoreContext = () => {
  return (
    <StoreProvider>
      <Shop />
    </StoreProvider>
  );
};

export default ShopWithStoreContext;
