import { Shortcodes } from '@jetshop/flight-shortcodes';
import React from 'react';
import { styled } from 'linaria/react';

import ContactForm from '../ContactForm/ContactForm';
import Bestsellers from '../Content/Bestsellers';
import Campaign from '../Content/Campaign';
import CampaignBox from '../Content/CampaignBox';
import MegaGallery from '../Content/MegaGallery';
import Photo from '../Content/Photo';
import PopularCategories from '../Content/PopularCategories';
import Quote from '../Content/Quote';
import Row from '../Content/Row';
import Column from '../Content/Column';
import Text from '../Content/Text';
import MaxWidth from '../Layout/MaxWidth';
import HeroImage from '../Content/HeroImage';
import Slide from '../Content/Slide';
import Category from '../Content/Category';
import HeroAndProducts from '../Content/HeroAndProducts';
import CategoryTriplets from '../Content/CategoryTriplets';
import CategoryCard from '../Content/CategoryCard';
import CampaignRow from '../Content/CampaignRow';
import Campaigns from '../Content/Campaigns';
import ColorBanner from '../Content/ColorBanner';
import SelectedProducts from '../Content/SelectedProducts';
import { VideoPopup } from '../Content/PreviewAndVideoPopup';
import { CustomHeader } from '../CategoryPage/CustomHeader';

import { theme } from '../Theme';
import Instagram from '../Content/Instagram';

export const components = {
  bestsellers: Bestsellers,
  campaign: Campaign,
  campaignBox: CampaignBox,
  column: Column,
  contactForm: ContactForm,
  hero: HeroImage,
  maxwidth: MaxWidth,
  megaGallery: MegaGallery,
  photo: Photo,
  popularCategories: PopularCategories,
  quote: Quote,
  row: Row,
  text: Text,
  slide: Slide,
  category: Category,
  heroAndProducts: HeroAndProducts,
  categoryTriplets: CategoryTriplets,
  categoryCard: CategoryCard,
  campaignRow: CampaignRow,
  campaigns: Campaigns,
  colorBanner: ColorBanner,
  selectedProducts: SelectedProducts,
  videoInPopup: VideoPopup,
  customHeader: CustomHeader,
  instagram: Instagram,
};

const ShortcodeWrapper = styled('div')`
  ${theme.below.md} {
    overflow: hidden;
  }
`;

// Removes all P tags
const removePandSpan = (content) =>
  content
    .replace(/<\/p>/g, '')
    .replace(/<p( [^>]+)?>/g, '')
    .replace(/<\/span>/g, '')
    .replace(/<span( [^>]+)?>/g, '');

const removeLonelyEndTags = (content, tags) => {
  tags.forEach((tag) => {
    const endTag = `</${tag}>`;

    const firstEndTag = content.indexOf(endTag);
    const firstStartTag = content.indexOf(`<${tag}`);

    if (firstEndTag < firstStartTag) {
      content =
        content.substring(0, firstEndTag) +
        content.substring(firstEndTag + endTag.length);
    }
  });

  return content;
};

const removeLonelyStartTags = (content, tags, endIndex) => {
  tags.forEach((tag) => {
    const startTag = `<${tag}`;

    const lastStartTag = content.lastIndexOf(startTag);
    const lastEndTag = content.lastIndexOf(`</${tag}>`);

    if (lastStartTag > lastEndTag) {
      content =
        content.substring(0, lastStartTag) + content.substring(endIndex);
      endIndex -= endIndex - lastStartTag;
    }
  });

  return content;
};

const findFirstOccurance = (content, tags) =>
  tags
    .map((tag) => ({
      index: content.indexOf(`[${tag}`),
      tag,
    }))
    .reduce(
      (lowest, current) => {
        if (current.index > -1) {
          if (lowest.index > -1) {
            if (current.index < lowest.index) {
              return current;
            }
          } else {
            return current;
          }
        }
        return lowest;
      },
      {
        index: -1,
        tag: null,
      }
    );

// This function removes P tags from everything but the content of [text]
const stripPTags = (content, allowedIn) => {
  let openTag = findFirstOccurance(content, allowedIn);

  // None of the allowed tags were found in content
  if (openTag.index === -1) {
    return removePandSpan(content);
  }

  let output = '';

  while (openTag.index > -1) {
    output += removePandSpan(content.substring(0, openTag.index));
    content = content.substring(openTag.index);

    content = removeLonelyEndTags(content, ['p', 'span']);

    // Find corresponding closing tag to the opening tag we're in
    const endTag = `[/${openTag.tag}]`;
    let endTagIndex = content.indexOf(endTag);

    let currentRow =
      endTagIndex > -1
        ? content.substring(0, endTagIndex + endTag.length)
        : content;

    currentRow = removeLonelyStartTags(currentRow, ['p', 'span'], endTagIndex);

    // Now push all this content
    output += currentRow;
    content = content.substring(endTagIndex + endTag.length);

    openTag = findFirstOccurance(content, allowedIn);
  }

  // Just append the remaining content
  output += removePandSpan(content);
  return output;
};

export default function DynamicContent({
  content,
  additionalComponents,
  allowP,
}) {
  const cleanedContent = allowP
    ? content
    : stripPTags(content.replace(/\u00a0/g, ' ').replace(/&amp;/g, '&'), [
        'text',
        'story',
      ]);

  const allComponents = { ...components, ...additionalComponents };
  return (
    <ShortcodeWrapper>
      <Shortcodes content={cleanedContent} components={allComponents} />
    </ShortcodeWrapper>
  );
}
