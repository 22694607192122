import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';

const ExternalLink = styled('a')``;

const InternalLink = styled(Link)``;

const CustomLink = ({ link, children }) => {
  switch (true) {
    case link?.includes('http'):
      return (
        <ExternalLink target="_blank" className="customLink" href={link}>
          {children}
        </ExternalLink>
      );
    case !!link:
      return (
        <InternalLink className="customLink" to={link}>
          {children}
        </InternalLink>
      );
    default:
      return <>{children}</>;
  }
};

export default CustomLink;
