import React from 'react';
import { styled } from 'linaria/react';

import { theme } from '../Theme';

const StyledRow = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;

  ${theme.above.sm} {
    flex-direction: row;
    margin-bottom: 2.5rem;

    > div {
      margin-right: 24px;
      margin-left: 24px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const Row = ({ children }) => (
  <StyledRow>
    {React.Children.toArray(children).filter((child) => child.type !== 'br')}
  </StyledRow>
);

export default Row;
