import React from 'react';
import { styled } from 'linaria/react';
import CampaignHeader from '../StartPage/CampaignHeader';
import { useQuery } from 'react-apollo';
import bestsellersQuery from './Bestsellers.gql';
import HeroProductCard from './HeroProductCard';

import { theme } from '../Theme';

const HeroAndProductsWrapper = styled('div')`
  display: flex;
  margin: auto;
  max-width: 100vw;
  width: 100%;

  ${theme.below.lg} {
    flex-direction: column;
  }
`;

const CampaignHeaderWrapper = styled('div')`
  width: 72.5%;
  /* max-width: 75%; */
  ${theme.below.lg} {
    width: 100%;
  }
`;

const ProductsWrapper = styled('div')`
  background: white;
  display: flex;
  flex-direction: column;
  width: 27.5%;

  ${theme.below.lg} {
    width: 100%;
    flex-direction: row;
  }
  ${theme.below.sm} {
    flex-direction: column;
    max-height: 1350px;
  }
`;

export const HeroAndProducts = (input) => {
  const artNrs = [];
  if (input.articleNumber1) {
    artNrs.push(input.articleNumber1);
  }
  if (input.articleNumber2) {
    artNrs.push(input.articleNumber2);
  }

  const { data, loading, error } = useQuery(bestsellersQuery, {
    variables: {
      articleNumbers: artNrs,
    },
  });

  if (error) return null;
  if (loading) return null;

  return (
    <HeroAndProductsWrapper>
      <CampaignHeaderWrapper>
        <CampaignHeader {...input} />
      </CampaignHeaderWrapper>
      <ProductsWrapper>
        {data?.products?.map((product, index) => (
          <HeroProductCard
            key={product.articleNumber + index}
            product={product}
          />
        ))}
      </ProductsWrapper>
    </HeroAndProductsWrapper>
  );
};
export default HeroAndProducts;
