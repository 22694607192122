import React from 'react';
import { useQuery } from 'react-apollo';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import StoreDetail from './StoreDetail';
import StoreQuery from './StoreQuery.gql';

const Wrapper = styled(MaxWidth)`
  margin: 2rem;
`;

export default function Store(props) {
  const {
    match: {
      params: { id: storeId },
    },
  } = props;

  const { data, loading, error } = useQuery(StoreQuery, {
    variables: { id: storeId },
  });

  if (loading) return <Wrapper>Loading…</Wrapper>;
  if (error) return 'Something went wrong';

  const { store } = data;

  return (
    <>
      {!store ? (
        'No store found!'
      ) : (
        <StoreDetail store={store} storeId={storeId} />
      )}
    </>
  );
}
