// import React, { useState, useEffect } from 'react';
// import { styled } from 'linaria/react';
// import MaxWidth from '../Layout/MaxWidth';
// import { ReactComponent as InstagramLogo } from '../../svg/InstagramLogo.svg';

// import { theme } from '../Theme';

// const MaxWidthWrapper = styled(MaxWidth)``;

// const FeedContainer = styled('div')`
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
//   grid-template-rows: repeat(2, 1fr);
//   grid-column-gap: 10px;
//   grid-row-gap: 10px;
//   margin-bottom: 4rem;

//   ${theme.below.md} {
//     grid-template-columns: repeat(2, 1fr);
//     grid-template-rows: repeat(3, 1fr);
//     grid-column-gap: 10px;
//     grid-row-gap: 10px;
//   }

//   .div0 {
//     background: #fa8072;
//     grid-area: 1 / 2 / 3 / 4;
//     ${theme.below.md} {
//       grid-area: 1 / 1 / 3 / 3;
//     }
//   }
//   .div1 {
//     background: #cd5c5c;
//     grid-area: 1 / 1 / 2 / 2;
//     ${theme.below.md} {
//       grid-area: 3 / 1 / 4 / 2;
//     }
//   }
//   .div2 {
//     background: #dc143c;
//     grid-area: 1 / 4 / 2 / 5;
//     ${theme.below.md} {
//       grid-area: 3 / 2 / 4 / 3;
//     }
//   }
//   .div3 {
//     background: #ff6347;
//     grid-area: 2 / 1 / 3 / 2;
//     ${theme.below.md} {
//       grid-area: 4 / 1 / 5 / 2;
//     }
//   }
//   .div4 {
//     background: #db7093;
//     grid-area: 2 / 4 / 3 / 5;
//     ${theme.below.md} {
//       grid-area: 4 / 2 / 5 / 3;
//     }
//   }
// `;

// const FeedNameWrapper = styled('div')`
//   margin-top: 2rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 0.8rem;

//   svg {
//     height: 31px;
//     width: 31px;
//     margin-right: 0.4rem;
//   }
// `;

// const FeedName = styled('div')`
//   font-family: Lato;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 16px;
//   text-align: center;
//   letter-spacing: 0.05em;
//   text-transform: uppercase;
//   padding-bottom: 2px;

//   a {
//     text-decoration: none;
//     color: black;

//     &:visited {
//       color: black;
//     }
//   }
// `;

// const InstagramImageWrapper = styled('div')`
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center;
//   padding-top: 100%;
//   position: relative;
//   overflow: hidden;
// `;

const Instagram = () => {
  console.warn('Trying to use nonusable Instagram component');
  return null;
  // let accountName = 'mobelmastarna';
  // let url = 'https://www.instagram.com/' + accountName + '/?__a=1';
  // const [fetchedData, setFetchedData] = useState();

  // useEffect(() => {
  //   if (fetchedData === undefined) {
  //     fetch(url)
  //       .then((response) => response.json())
  //       .then((json) => {
  //         setFetchedData(json);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   }
  // });

  // let postList =
  //   fetchedData?.graphql?.user?.edge_owner_to_timeline_media?.edges.slice(0, 5);

  // if (!postList) {
  //   return null;
  // }
  // return (
  //   <MaxWidthWrapper>
  //     <FeedNameWrapper>
  //       <InstagramLogo />
  //       <FeedName>
  //         <a
  //           href={'https://instagram.com/mobelmastarna'}
  //           target="_blank"
  //           rel="noreferrer"
  //         >
  //           @mobelmastarna
  //         </a>
  //       </FeedName>
  //     </FeedNameWrapper>
  //     <FeedContainer>
  //       {postList.map((image, index) => {
  //         const { shortcode, display_url } = image.node;
  //         return (
  //           <a
  //             href={'https://www.instagram.com/p/' + shortcode}
  //             target="_blank"
  //             rel="noopener noreferrer"
  //             className={'div' + index}
  //             key={'feedItem_' + index}
  //           >
  //             <InstagramImageWrapper
  //               style={{
  //                 backgroundImage: `url(${display_url})`,
  //               }}
  //             />
  //           </a>
  //         );
  //       })}
  //     </FeedContainer>
  //   </MaxWidthWrapper>
  // );
};

export default Instagram;
