import React from 'react';
import { css, cx } from 'linaria';

const hamburger = css`
  display: inline-flex;

    .hamburger-box {
      width: 30px;
      height: 12px;
      position: relative;
    }

    .hamburger-inner {
      display: block;
      top: 50%;
      margin-top: -2px;
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      width: 30px;
      height: 1px;
      background-color: #111111;
      border-radius: 2px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    .hamburger-inner::before,
    .hamburger-inner::after {
      content: '';
      display: block;
    }

    .hamburger-inner::before {
      top: -7px;
      transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    }

    .hamburger-inner::after {
      bottom: -7px;
      transition: bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
`;

const isActive = css`
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: #111111;
  }

  .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }

  .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease,
      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;

const MenuIcon = ({ showMenu }) => {
  return (
    <div className={cx(hamburger, showMenu && isActive)}>
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </div>
  );
};

export default MenuIcon;
