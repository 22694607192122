import { useIntl } from '@jetshop/intl';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems,
} from '@jetshop/ui/DropdownMenu';
import React, { useState } from 'react';
import { css } from 'linaria';
import { ReactComponent as Carrot } from '../../svg/Carrot.svg';
import ButtonWithLoading from '../ui/Button';
import { SectionHeading } from './ProductInfo';
import ColorBox from '../ui/ColorBox';

const buttonSelectStyles = css`
  position: relative;
  h3 {
    margin-top: 0;
  }
  .button-container {
    margin: 0 -1.25rem;
  }
  button {
    border: none;
    padding: 0;
    line-height: 36px;
    font-size: 16px;
    outline: none;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 1rem;
    align-items: center;
    justify-content: center;
    border: 0.75px solid transparent;
    background-color: transparent;
    display: inline-block;
    &[aria-pressed='true'] {
      background-color: black;
    }
  }

  .color-selection {
    display: flex;
    gap: 0.8rem;
    margin: 0 0 20px 0;
    padding: 8px 24px 8px 4px;
    overflow: scroll;

    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  ::after {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: auto;
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 1) 100%
    );
    height: 100%;
    width: 60px;
    content: '';
  }
`;

const dropdownStyles = css`
  margin-bottom: 1em;
  h3 {
    padding-bottom: 0;
  }

  [data-flight-dropdown] {
    position: relative;
    background: white;
  }
  [data-flight-dropdown-button] {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border: 1px solid #dedede;
    position: relative;
    cursor: pointer;
    outline: none;
    width: 100%;
    font-size: 15px;
    padding-left: 16px;
    svg {
      margin-right: 16px;
      transition: transform 200ms;
    }
  }
  [data-flight-dropdown-open='true'] {
    [data-flight-dropdown-button] {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
  [data-flight-dropdown-items] {
    width: 100%;
    margin-top: -1px;
    border: 1px solid #dedede;
    border-bottom: none;
    position: absolute;
  }
  [data-flight-dropdown-item] {
    justify-content: space-between;
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dedede;
    position: relative;
    cursor: pointer;
    background-color: #ffffff;
    color: black;
    font-size: 15px;
    padding-left: 16px;

    :hover {
      background-color: #f2f2f2;
      color: black;
      outline: none;
    }
    :focus {
      outline: none;
    }
  }
  svg {
    margin-left: auto;
  }
`;

export function VariantSelector({
  product,
  variantHandler,
  selectedVariation,
  colorNestled,
}) {
  return (
    <div className="variant-selector" style={{ width: '100%' }}>
      {product.variants.options.map((option) =>
        option.name.toLowerCase() === 'size' ||
        option.name.toLowerCase() === 'färg' ? (
          <ButtonSelect
            option={option}
            variantHandler={variantHandler}
            key={option.name}
            colorSelection={option.name.toLowerCase() === 'färg'}
            selectedVariation={selectedVariation}
            colorNestled={colorNestled}
          />
        ) : (
          <DropdownVariantSelect
            key={option.name}
            option={option}
            variantHandler={variantHandler}
          />
        )
      )}
    </div>
  );
}

function ButtonSelect({
  option,
  variantHandler,
  selectedVariation,
  colorSelection = false,
}) {
  const { getSelectedValue, validateSelection, selectValue } = variantHandler;
  const selectedValue = getSelectedValue(option);
  const t = useIntl();
  const colorVariants = colorSelection ? 'color-selection' : '';

  const matchSelectedVariation = selectedVariation?.values[0];

  return (
    <div className={buttonSelectStyles}>
      <SectionHeading>
        {t('Select {optionName}', { optionName: option.name })}
      </SectionHeading>
      <div className={'button-container ' + colorVariants}>
        {option.values.map((value) => {
          const validation = validateSelection(value, option);
          return colorSelection ? (
            <ColorBox
              group={value}
              key={option.name + value}
              clickClosure={() => selectValue(value, option)}
              boxsize={'big'}
              active={matchSelectedVariation === value && true}
              showLabel={true}
            />
          ) : (
            <ButtonWithLoading
              key={option.name + value}
              onClick={() => selectValue(value, option)}
              disabled={validation === 'invalid'}
              secondary={value !== selectedValue}
              aria-pressed={value === selectedValue}
            >
              {value}
            </ButtonWithLoading>
          );
        })}
      </div>
    </div>
  );
}

function DropdownVariantSelect({ option, variantHandler }) {
  const { getSelectedValue, validateSelection, selectValue } = variantHandler;

  const selectedValue = getSelectedValue(option);
  return (
    <div className={dropdownStyles}>
      <SectionHeading>{option.name}</SectionHeading>
      <DropdownMenu unstyled>
        <DropdownMenuButton id={`option-${option.name}`}>
          {selectedValue && selectedValue}
          <Carrot width="13" height="7" />
        </DropdownMenuButton>
        <DropdownMenuItems style={{ zIndex: 9999 }}>
          {option.values.map((value) => {
            const validation = validateSelection(value, option);
            return (
              <DropdownMenuItem
                key={value + option.name}
                disabled={validation === 'invalid'}
                onSelect={({ setIsOpen }) => {
                  selectValue(value, option);
                  setIsOpen(false);
                }}
                style={{ opacity: validation === 'invalid' ? 0.5 : 1 }}
              >
                {value}
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuItems>
      </DropdownMenu>
    </div>
  );
}
