import React from 'react';
import { styled } from 'linaria/react';
import get from 'lodash.get';

import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { Price } from '@jetshop/ui/Price';

import Button from '../ui/Button';
import CartItem from './CartItem';
import cartQuery from './CartQuery.gql';

import { ReactComponent as CrossSvg } from '../../svg/Cross.svg';
import { ReactComponent as CartSvg } from '../../svg/Cart.svg';

import { theme } from '../Theme';

const Flyout = styled('div')`
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
  overflow-y: auto;
  padding: 24px 24px 8px;
  height: 100vh;

  ${theme.below.md} {
    padding: 24px 16px 8px;
  }

  position: relative;
  z-index: 12;
`;

const Header = styled('header')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 36px 0;

  h2 {
    font-size: 14px;
    line-height: 179%;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    font-weight: 700;

    color: #000000;

    display: flex;
    align-items: center;

    span {
      margin-left: 16px;
    }
  }
`;

const CloseNav = styled('button')`
  outline: none;
  background: #ffffff;
  border: none;

  svg {
    height: 14px;
    width: 14px;
    stroke-width: 0.5px;

    path {
      stroke: #111111;
    }
  }
`;

const CartItemsHeader = styled('p')`
  font-size: 12px;
  color: #b6b6b6;
  letter-spacing: 0.2px;
  margin-top: 36px;
`;

const CartItems = styled('section')`
  background: #ffffff;
  margin: 8px 0 24px;
  padding: 1rem 0 0;

  border-top: 1px solid #e0e0e0;

  > div {
    border-bottom: 1px solid #e0e0e0;
  }
`;

const Summary = styled('section')`
  background: #fff;
  margin: 24px 0;
`;

const TotalSummary = styled('section')`
  justify-content: space-between;
  font-size: 14px;
  line-height: 179%;
  letter-spacing: 0.2px;

  display: flex;
  align-items: center;

  color: ${theme.colors.black};
  margin-bottom: 1rem;

  font-weight: 700;
`;

const Flex = styled('div')`
  display: flex;

  ${theme.below.md} {
    flex-direction: column;
  }
`;

const Checkout = styled(Button)`
  flex: 1;
  margin-right: 8px;
  min-height: 40px;
  color: white !important;
  background: #720714;

  &:hover {
    text-decoration: none !important;
    background: #720714;
  }

  ${theme.below.md} {
    margin-right: 0;
    margin-bottom: 8px;
    width: 100%;
  }
`;

const ContinueShopping = styled(Button)`
  flex: 1;
  margin-left: 8px;
  min-height: 40px;
  background: #f6f6f6;
  color: black;

  &:hover {
    text-decoration: none;
    background: black;
    color: white;
  }

  ${theme.below.md} {
    margin-left: 0;
    width: 100%;
  }
`;

const CartIcon = styled(CartSvg)`
  width: 16px;
  height: 16px;

  path {
    stroke: #111;
  }
`;

const CartFlyoutView = ({ cart, modal, ...rest }) => {
  const items = get(cart, 'data.cart.items', []);
  const itemCount = get(cart, 'data.cart.totalQuantity', 0);
  const checkoutUrl = get(cart, 'data.cart.externalCheckoutUrl');

  const track = useTracker();

  return (
    <Flyout {...rest} className='hideInPrint'>
      <Header>
        <h2>
          <CartIcon />
          <span>{t('Your cart')}</span>
        </h2>
        <CloseNav onClick={modal.hideTarget}>
          <CrossSvg />
        </CloseNav>
      </Header>

      <Summary>
        <TotalSummary>
          <label>
            {itemCount} {t('products in total')}
          </label>
          <Price price={cart.data.cart.productTotal} />
        </TotalSummary>
        <Flex>
          {checkoutUrl ? (
            <Checkout
              anchor={true}
              href={checkoutUrl}
              onClick={(event) => {
                event.preventDefault();
                    track (
                        trackCartCheckoutEvent({
                            cart: cart.data.cart,
                            callback: () => {
                            window.location = checkoutUrl;
                            },
                        })
                    );
              }}
            >
              {t('Check out')}
            </Checkout>
          ) : (
            <Checkout disabled>{t('Check out')}</Checkout>
          )}
          <ContinueShopping secondary onClick={modal.hideTarget}>
            {t('Continue shopping')}
          </ContinueShopping>
        </Flex>
      </Summary>

      <CartItemsHeader>
        {itemCount > 0 ? t('Products') : t('0 products')}
      </CartItemsHeader>
      <CartItems>
        {items.map((item) => (
          <CartItem item={item} key={item.id} hideTarget={modal.hideTarget} />
        ))}
      </CartItems>
      {itemCount > 0 && (
        <TotalSummary>
          <label>{t('Total')}</label>
          <Price price={cart.data.cart.productTotal} />
        </TotalSummary>
      )}
    </Flyout>
  );
};

const CartFlyout = (props) => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {(cart) =>
      cart.data && cart.data.cart && typeof window !== 'undefined' ? (
        <DrawerTarget id="cart-drawer">
          {(drawer) => (
            <Drawer
              isOpen={drawer.isOpen}
              right
              size={Math.min(Math.max(320, window.screen.width - 55), 450)}
            >
              <CartFlyoutView modal={drawer} cart={cart} {...props} />
            </Drawer>
          )}
        </DrawerTarget>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
