import React from 'react';
import { styled } from 'linaria/react';

export const VideoWrapper = styled('div')`
  position: relative;
  padding-bottom: 56.25%;
  margin-bottom: 0px;
  width: 100% !important;
  height: auto !important;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const Video = ({ videoSrc }) => {
  let url = videoSrc;
  //check if vimeo
  if (videoSrc.includes('vimeo.com')) {
    const vPlayerUrl = videoSrc.replace(
      'vimeo.com/',
      'player.vimeo.com/video/'
    );
    url = vPlayerUrl + '?background=1';
    //check if youtube
  } else if (videoSrc.includes('youtu.be')) {
    const yPlayerUrl = videoSrc.replace(
      'https://youtu.be/',
      'https://www.youtube.com/embed/'
    );

    url = yPlayerUrl + '?autoplay=1&controls=0&loop=1';
    //check if youtube
  } else if (videoSrc.includes('youtube.com/watch')) {
    const yPlayerUrl = videoSrc.replace(
      'https://www.youtube.com/watch?v=',
      'https://www.youtube.com/embed/'
    );

    url = yPlayerUrl + '?autoplay=1&controls=0&loop=1';
  } else {
    return null;
  }

  return (
    <VideoWrapper className={'video vimeo'}>
      <iframe title={`Video: ${url}`} src={url} allow="autoplay" />
    </VideoWrapper>
  );
};

export default Video;
