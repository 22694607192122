import { styled } from 'linaria/react';
import React from 'react';

import { theme } from '../Theme';

const CutOffWrapper = styled('div')`
  overflow-x: hidden;
  width: 100%;
  padding: 8px 0 28px;
  text-align: center;
`;

const WithLines = styled('p')`
  line-height: 0.5;
  text-align: center;
  font-size: ${theme.fontSizes[4]};

  span {
    display: inline-block;
    position: relative;
    color: #111;
    font-weight: ${theme.fontWeights.light};
  }

  span:before,
  span:after {
    content: '';
    position: absolute;
    height: 7px;
    border-bottom: 1px solid #b6b6b6;
    top: 0;
    width: 800px;
  }
  span:before {
    right: 100%;
    margin-right: 15px;
  }
  span:after {
    left: 100%;
    margin-left: 15px;
  }

  ${theme.below.md} {
    font-size: ${theme.fontSizes[2]};
  }
`;

const TitleWithLines = ({ children }) => (
  <CutOffWrapper>
    <WithLines>
      <span>{children}</span>
    </WithLines>
  </CutOffWrapper>
);
export default TitleWithLines;
