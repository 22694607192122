import React, { Fragment } from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import get from 'lodash.get';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { theme } from '../Theme';
import { ReactComponent as Cart } from '../../svg/Cart.svg';
import t from '@jetshop/intl';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import ToastContainerWrapper from './ToastContainerWrapper';
import { ToastContainer } from 'react-toastify';
import cartQuery from './CartQuery.gql';

const Button = styled('button')`
  height: 54px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.white};
  padding: 8px;
  border-radius: 4px;

  svg {
    width: 20px;
    height: 20px;
  }

  span {
    margin-top: 5px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    color: #333333;
  }

  ${theme.below.sm} {
    padding: 8px 2px;
    margin-left: 0;
  }

  ${theme.above.md} {
    margin-left: 64px;

    &:hover {
      background: ${theme.colors.notWhite};
    }
  }
`;

const CartLabel = styled('label')`
  font-size: 12px;
  letter-spacing: 0.2px;
  color: #333333;
  margin-top: 3px;

  ${theme.above.md} {
    text-transform: uppercase;
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  ${theme.above.sm} {
    width: unset;
  }
`;

function CartButton() {
  return (
    <Fragment>
      <ToastContainerWrapper>
        <StyledToastContainer
          toastClassName={css`
            padding: 0px;
            cursor: auto;
            font-family: inherit;
          `}
          autoClose={false}
          hideProgressBar
          closeButton={false}
          closeOnClick={false}
        />
      </ToastContainerWrapper>
      <CartProvider query={cartQuery}>
        {(result) => {
          // Set items in cart to the API result.
          // If the result is undefined, fall back to 0
          const itemsInCart = get(result, 'data.cart.totalQuantity', 0);

          return (
            <DrawerTrigger
              preventOverflow={true}
              id="cart-drawer"
              coverStyles={{ zIndex: '11' }}
            >
              {(drawer) => (
                <Button
                  data-testid="header-cart"
                  className="cart-button"
                  style={{ border: 'none' }}
                  onClick={
                    drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                  }
                >
                  <Cart />
                  <CartLabel>
                    {t('Cart')} ({itemsInCart})
                  </CartLabel>
                </Button>
              )}
            </DrawerTrigger>
          );
        }}
      </CartProvider>
    </Fragment>
  );
}

export default CartButton;
