import React from 'react';
import { styled } from 'linaria/react';
import posed from 'react-pose';
import CategoryLink from '@jetshop/ui/CategoryLink';
import MaxWidth from '../../MaxWidth';

import { theme } from '../../../Theme';

const PosedWrapper = styled(
  posed('section')({
    open: {
      height: 'auto',
    },
    closed: {
      height: 0,
    },
  })
)`
  background: ${theme.colors.white};
  margin-top: 1px;
  position: absolute;
  right: 0;
  left: 0;
  overflow: hidden;
  z-index: 7;
  box-shadow: 0px 3px 5px 0px rgba(150, 150, 150, 0.1);
`;

const InnerWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0;
  margin: 0;
`;

const SubMenuContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${`${theme.space[5]} 0`};
  width: 40vw;
  max-width: 40rem;

  h3 {
    margin: 0;
  }
`;

const ImageContainer = styled('div')`
  padding: ${`${theme.space[5]} 0`};
  max-width: 100%;
  justify-content: flex-end;

  img {
    max-height: 320px;
  }
`;

const CategoryTitle = styled('p')`
  width: 100%;
  margin-top: 0;
  margin-bottom: ${theme.space[2]};
  font-size: 12px;
  font-weight: 700;
  color: #b6b6b6;
  text-transform: uppercase;
  letter-spacing: 0.6px;
`;

const NoThirdTierItems = styled('div')`
  width: 50%;

  h3 {
    font-size: ${theme.fontSizes[2]};
    font-weight: 700;
    letter-spacing: 0.2px;
    line-height: 1.6;
    padding: 0.5rem;
    margin-left: -1rem;
  }

  h3 > a {
    color: #111;
    padding: 0.2rem 0.5rem;
    border-radius: 2px;
    text-decoration: none;

    &:hover {
      background: ${theme.colors.notWhite};
      text-decoration: none;
    }
  }
`;

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client, data }) => {
  return (
    <PosedWrapper pose={pose}>
      <MaxWidth>
        <InnerWrapper>
          <SubMenuContainer>
            <CategoryTitle>
              {activeCategory && activeCategory.name}
            </CategoryTitle>
            {activeCategory &&
              activeCategory.subcategories.map((cat) => (
                <NoThirdTierItems key={cat.id}>
                  <h3>
                    <CategoryLink category={cat} onClick={closeNav}>
                      {cat.name}
                    </CategoryLink>
                  </h3>
                </NoThirdTierItems>
              ))}
          </SubMenuContainer>
          <ImageContainer>
            {activeCategory && activeCategory.images.length > 0 && (
              <img
                src={activeCategory.images[0].url}
                alt={activeCategory.name}
              />
            )}
          </ImageContainer>
        </InnerWrapper>
      </MaxWidth>
    </PosedWrapper>
  );
};

export default SubMenuWrapper;
