import { useEffect } from 'react';

export const useLipscore = () => {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    // Only add script if it's not already there
    if (window.lipscore) {
      window.lipscore.initWidgets();
      return;
    }

    // Initialize lipscore
    window.lipscoreInit = function () {
      window.lipscore.init({
        apiKey: 'bea38eb1dfc86107b7f011c6',
      });
    };

    // Create and append script
    const script = document.createElement('script');
    script.async = true;
    script.src = '//static.lipscore.com/assets/sv/lipscore-v1.js';
    script.onload = () => window.lipscore.initWidgets();

    document.head.appendChild(script);

    // Cleanup
    return () => {
      if (document.head.contains(script)) {
        document.head.removeChild(script);
      }
    };
  }, []);
};
