import LatoLightEot from './lato-v15-latin-300.eot';
import LatoLightWoff from './lato-v15-latin-300.woff';
import LatoLightWoff2 from './lato-v15-latin-300.woff2';
import LatoLightTtf from './lato-v15-latin-300.ttf';
import LatoRegularEot from './lato-v15-latin-regular.eot';
import LatoRegularWoff from './lato-v15-latin-regular.woff';
import LatoRegularWoff2 from './lato-v15-latin-regular.woff2';
import LatoRegularTtf from './lato-v15-latin-regular.ttf';
import LatoSemiBoldEot from './Lato-v15-latin-600.eot';
import LatoSemiBoldWoff from './Lato-v15-latin-600.woff';
import LatoSemiBoldWoff2 from './Lato-v15-latin-600.woff2';
import LatoSemiBoldTtf from './Lato-v15-latin-600.ttf';
import LatoBoldEot from './lato-v15-latin-700.eot';
import LatoBoldWoff from './lato-v15-latin-700.woff';
import LatoBoldWoff2 from './lato-v15-latin-700.woff2';
import LatoBoldTtf from './lato-v15-latin-700.ttf';
import LatoBlackEot from './Lato-Black.eot';
import LatoBlackWoff from './Lato-Black.woff';
import LatoBlackWoff2 from './Lato-Black.woff2';
import LatoBlackTtf from './Lato-Black.ttf';

import { css } from 'linaria';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
    /* Light font */
    @font-face {
      font-family: 'Lato';
      src: url(${LatoLightEot}); /* IE9 Compat Mode */
      src: local('Lato Light'), local('Lato-Light'),
        url(${LatoLightWoff2}) format('woff2'),
        url(${LatoLightWoff}) format('woff'),
        url(${LatoLightTtf}) format('truetype');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    /* Regular font */
    @font-face {
      font-family: 'Lato';
      src: url(${LatoRegularEot}); /* IE9 Compat Mode */
      src: local('Lato Regular'), local('Lato-Regular'),
        url(${LatoRegularWoff2}) format('woff2'),
        url(${LatoRegularWoff}) format('woff'),
        url(${LatoRegularTtf}) format('truetype');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
    }

    /* SemiBold font */
    @font-face {
      font-family: 'Lato';
      src: url(${LatoSemiBoldEot}); /* IE9 Compat Mode */
      src: local('Lato SemiBold'), local('Lato-SemiBold'),
        url(${LatoSemiBoldWoff2}) format('woff2'),
        url(${LatoSemiBoldWoff}) format('woff'),
        url(${LatoSemiBoldTtf}) format('truetype');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
    }

    /* Bold font */
    @font-face {
      font-family: 'Lato';
      src: url(${LatoBoldEot}); /* IE9 Compat Mode */
      src: local('Lato Bold'), local('Lato-Bold'),
        url(${LatoBoldWoff2}) format('woff2'),
        url(${LatoBoldWoff}) format('woff'),
        url(${LatoBoldTtf}) format('truetype');
      font-weight: 700;
      font-style: normal;
      font-display: swap;
    }

    /* Black font */
    @font-face {
      font-family: 'Lato';
      src: url(${LatoBlackEot}); /* IE9 Compat Mode */
      src: local('Lato Black'), local('Lato-Black'),
        url(${LatoBlackWoff2}) format('woff2'),
        url(${LatoBlackWoff}) format('woff'),
        url(${LatoBlackTtf}) format('truetype');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
    }
  `;
}
