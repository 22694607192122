import React from 'react';
import UIButton from '@jetshop/ui/Button';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import { theme } from '../Theme';

export const buttonStyle = `
  width: 200px;
  height: 46px;
  font-size: 14px;
  font-weight: 700;
  font-family: ${theme.fonts.primary};
  color: ${theme.colors.white};
  background: #911827;
  border: 0;
  // border-radius: 4px;
  outline: none;
  cursor: pointer;

  ${theme.below.md} {
    height: 40px;
  }

  &:hover,
  &:focus {
    background: #720714;
    text-decoration: none;
  }

`;

export const TrendLink = styled(Link)`
  ${buttonStyle};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

export const Button = styled(UIButton)`
  ${buttonStyle};
`;

const ButtonWithLoading = ({ loading, loadingText, ...props }) =>
  loading ? (
    <Button>
      {loadingText}
      <Spinner />
    </Button>
  ) : (
    <Button {...props} />
  );

export default ButtonWithLoading;
