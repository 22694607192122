import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const Text = styled('div')`
  margin: 0;

  text-align: ${(props) => props.align};
  h2 {
    margin: 0 0 2rem;
    font-size: 36px;
    font-family: Lato, sans-serif;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    line-height: 1.2;
    font-weight: 300;

    ${theme.below.sm} {
      font-size: 25px;
      margin: 0 0 1rem;
    }
  }

  h3 {
    margin: 1rem 0;
    font-size: 25px;
    line-height: 25px
    font-family: Lato, sans-serif;
    letter-spacing: 0.2px;
    font-weight: 400;
  }

  h4 {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;   
    font-family: Lato, sans-serif;
    letter-spacing: 0.2px;
    margin: 1rem 0;
  }
  p {
    font-size: 14px;
    font-family: Lato, sans-serif;
    font-weight: ${theme.fontWeights.light};
    letter-spacing: 0.2px;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 1rem;

    ${theme.below.sm} {
      font-size: ${theme.fontSizes[2]};
    }
  }
  a {
    color: ${theme.colors.black};
    font-weight: 700;
  }
  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }

  ul {
    list-style-type: disc;
    padding-left: 0px!important;
  }

  ol {
    list-style-type: decimal;
    margin-bottom: 16px;
    padding-left: none;
  }

  li {
    margin-left: 1rem;
    margin-bottom: 8px;
  }

  table,
  th,
  td {
    border: 1px solid #000;
  }

  table {
    border-collapse: collapse;
  }

  th,
  td {
    padding: 0.25rem;
  }

  th p,
  td p {
    margin: 0;
  }
`;

const TextComp = ({ children, align }) => {
  return <Text align={align || 'left'}>{children}</Text>;
};

export default TextComp;
