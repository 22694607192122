import t from '@jetshop/intl';
import { ErrorMessage, Field } from 'formik';
import get from 'lodash.get';
import React from 'react';
import { styled } from 'linaria/react';
import { InputError, InputPositive } from './InputStatus';

const Input = styled('input')`
  text-align: center;
  height: 50px;
  width: 50px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-family: Lato;
  color: #111111;
  font-size: 16px;
  margin-right: 8px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
  &:disabled {
    background: #ffffff;
    color: #808080;
  }
`;

export const Wrapper = styled('div')`
  input {
    border-radius: 0 !important;
  }
`;

export const ErrorSpan = styled('span')`
  font-style: italic;
  font-size: 12px;
  color: #ff0000;
  position: absolute;
`;

export const Label = styled('label')`
  display: block;
  font-size: 12px;
  margin-bottom: 0.25rem;
  display: block;
  .req {
    color: hsl(0, 0%, 60%);
    margin-left: 0.5em;
  }
`;

const InputWithLabel = ({
  label,
  disabled,
  error,
  success,
  warning,
  loading,
  name,
  required,
  type,
  disableValidation,
  ...props
}) => {
  return (
    <Wrapper className={props.wrapperClassName}>
      <Label disabled={disabled} htmlFor={name}>
        <span>{label || name}</span>
        {required && <span className="req">{t('(Required)')}</span>}
      </Label>
      <Field
        disabled={disabled}
        id={name}
        name={name}
        render={({ field, form: { touched, errors } }) => (
          <div>
            <Input {...props} {...field} type={type} />
            {!disableValidation && (
              <>
                {get(touched, field.name) && get(errors, field.name) && (
                  <InputError />
                )}
                {get(touched, field.name) && !get(errors, field.name) && (
                  <InputPositive />
                )}
              </>
            )}
          </div>
        )}
      />
      {!disableValidation && <ErrorMessage name={name} component={ErrorSpan} />}
    </Wrapper>
  );
};

export default InputWithLabel;
