import { css } from 'linaria';

const fallbackStyles = `
  font-family: system-ui;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -1.05px;
  word-spacing: 0px;
  font-weight: 400;
  visibility: visible;
`;

const globals = `
  html, body {
    box-sizing: border-box;
    margin:0;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    ${fallbackStyles};
    font-family: 'Lato';
    letter-spacing: normal;

    @media print {
        margin-top: 25px;
        margin-bottom: 25px;
        zoom: 70%;
      }
  }

  .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.1) !important;
    z-index: 14;
    overflow: scroll;
  }
  strong {
    font-weight: 700;
  }
  em {
    font-style: italic;
  }

  .hideInPrint {
    @media print {
      display: none;
    }
  }

  .showInPrint {
    display: none;

    @media print {
        display: block;
    }

    &.dateInPrint {
        position: absolute;
        top: 0;
    }
  }

  .hideInDisplay {
      @media screen {
      display: none;
    }
  }

  .printLogo {
    @media print {
      display: flex;
      justify-content: center;
      margin-bottom: 2.5rem;
    }
  }

  .avoidCuttingInPrint {
    page-break-inside: avoid;
  }

  .marginTopInPrint {
    @media print {
        padding-top: 4rem;
    }
  }

  .image-gallery-image > * {

    @media print {
      padding-bottom: 0 !important;
      height: 450px !important;
    }
  }

  * {
      @media print {
      -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
      color-adjust: exact !important;  /*Firefox*/
    }
  }

  dialog::backdrop {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}
`;

css`
  :global() {
    ${globals};
  }
`;
