import React from 'react';
import { styled } from 'linaria/react';
import SearchField from './SearchField';
import { theme } from '../../Theme';
import { Intl } from '@jetshop/intl';
import { ReactComponent as CloseSvg } from '../../../svg/Cross.svg';
import AutocompleteQuery from './AutocompleteQuery.gql';

const SearchFieldContainer = styled('div')`
  position: relative;

  margin-bottom: 4px;

  ${theme.below.md} {
    width: calc(100% - 36px);
    margin: 12px auto 12px 0;
    padding: 0 1rem;
  }
`;

const StyledSearchField = styled(SearchField)`
  ${SearchField.Wrapper} {
    width: 100%;
    display: flex;
    height: 40px;
    z-index: 8;

    input {
      border: 0;
      background: #f3f3f3;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 4px;
      padding: 0 20px;
      font-size: ${theme.fontSizes[1]};
      font-family: ${theme.fonts.primary};

      width: calc(50vw - 128px - 32px);
      max-width: calc(640px - 128px - 32px);

      ${theme.below.md} {
        width: 100%;
        max-width: unset;
      }

      /* clears the 'X' from Internet Explorer */
      &[type='search']::-ms-clear {
        display: none;
        width: 0;
        height: 0;
      }
      &[type='search']::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }

      /* clears the 'X' from Chrome */
      &[type='search']::-webkit-search-decoration,
      &[type='search']::-webkit-search-cancel-button,
      &[type='search']::-webkit-search-results-button,
      &[type='search']::-webkit-search-results-decoration {
        display: none;
      }

      &::placeholder {
        color: #afafaf;
        font-size: ${theme.fontSizes[1]};
      }
    }
  }

  ${SearchField.Cancel} {
    display: none;
  }

  ${SearchField.Flyout} {
    text-align: left;
    ${theme.below.md} {
      top: 4rem;
    }
    > * {
      padding: 0.5rem;
    }
    h2 {
      color: #999999;
      text-transform: uppercase;
      font-size: 0.75rem;
      padding: 0.5rem;
    }
    li {
      padding: 0.5rem;
      border-top: 1px solid #e8e8e8;
    }
    a {
      display: block;
      text-decoration: none;
      padding: 8px 0;
      color: ${theme.colors.black};
    }
  }
`;

const CloseButton = styled('button')`
  position: absolute;
  height: 32px;
  width: 32px;
  top: 4px;
  left: calc(100% - 8px);

  padding: 8px;

  background: transparent;

  z-index: 9;

  stroke-width: 0.5px;
  svg path {
    stroke: #000;
  }

  ${theme.above.md} {
    display: none;
  }
`;

const FullSearchField = (props) => (
  <SearchFieldContainer>
    <Intl>
      {(t) => (
        <StyledSearchField
          autocompleteQuery={AutocompleteQuery}
          placeholder={t('Search product')}
          onCancel={() => {}}
          {...props}
        />
      )}
    </Intl>
    <CloseButton onClick={props.onCancel}>
      <CloseSvg />
    </CloseButton>
  </SearchFieldContainer>
);

export default FullSearchField;
