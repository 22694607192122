import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import bestsellersQuery from './Bestsellers.gql';
import Slider from 'react-slick';
import './slickCSS.css';
import { useQuery } from 'react-apollo';
import { ReactComponent as Arrow } from '../../svg/Arrow.svg';
import { ProductComponent } from '../CategoryPage/ProductGrid';

import { theme } from '../Theme';

const Container = styled('div')`
  [data-testid='product'] {
    width: 100%;
  }

  .slick-track {
    padding-left: 2px;
  }

  [aria-hidden='false'] {
    margin-left: -1px;
  }

  margin-bottom: 2rem;
`;

const ArrowWrapper = styled('div')`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  z-index: 1;
  background: white;

  &.next {
    transform: rotate(270deg);
    right: -15px;
    left: auto;
    ${theme.below.md} {
      right: 0;
    }
  }

  &.prev {
    transform: rotate(90deg);
    right: auto;
    left: -15px;
    ${theme.below.md} {
      left: 0;
    }
  }
`;

const CustomArrow = styled(Arrow)`
  height: 14px;
  width: 14px;
`;

const Title = styled('h1')`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <ArrowWrapper className={className + ' prev'} onClick={onClick}>
      <CustomArrow />
    </ArrowWrapper>
  );
}

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <ArrowWrapper className={className + ' next'} onClick={onClick}>
      <CustomArrow />
    </ArrowWrapper>
  );
}

const Bestsellers = ({ articleNumbers, title }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    draggable: true,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const artNr = articleNumbers
    .split(',')
    .map((articleNumber) => articleNumber.trim());

  const bsellers = useQuery(bestsellersQuery, {
    variables: {
      articleNumbers: artNr,
    },
    errorPolicy: 'ignore',
  });

  if (bsellers.error) return null;
  if (bsellers.loading) return null;

  return (
    <MaxWidth>
      <Container>
        {title && <Title>{title}</Title>}
        <Slider {...settings}>
          {bsellers?.data?.products?.map((product, index) => (
            <ProductComponent
              key={index + product.articleNumber}
              product={product}
            ></ProductComponent>
          ))}
        </Slider>
      </Container>
    </MaxWidth>
  );
};
export default Bestsellers;
