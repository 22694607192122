import React from 'react';
import { ReactComponent as InputErrorSVG } from '../../svg/InputError.svg';
import { ReactComponent as InputPositiveSVG } from '../../svg/InputPositive.svg';
import { styled } from 'linaria/react';

const StatusRelativeWrapper = styled('div')`
  position: relative;

  &.hidden {
    opacity: 0.5;
    :hover {
      opacity: 1;
    }
  }
`;

const StatusWrapper = styled('div')`
  position: absolute;
  right: 0px;
  margin-right: 10px;
  top: -34px;
`;

export const InputError = () => (
  <StatusRelativeWrapper>
    <StatusWrapper className="status-wrapper">
      <InputErrorSVG />
    </StatusWrapper>
  </StatusRelativeWrapper>
);

export const InputPositive = () => (
  <StatusRelativeWrapper>
    <StatusWrapper className="status-wrapper">
      <InputPositiveSVG />
    </StatusWrapper>
  </StatusRelativeWrapper>
);
