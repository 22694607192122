import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { theme } from '../Theme';

import MaxWidth from '../Layout/MaxWidth';
import CircleBadge from '../ui/CircleBadge';
import { Link } from 'react-router-dom';

const Container = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${theme.colors.black};

  background: ${theme.colors.beige};
`;

const Title = styled('h2')`
  font-family: ${theme.fonts.primary}, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: ${theme.fontSizes[8]};
  margin-bottom: ${theme.space[3]};
  text-transform: uppercase;

  ${theme.below.md} {
    font-size: ${theme.fontSizes[6]};
    margin-bottom: ${theme.space[2]};
  }
`;

const Grid = styled('div')`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: ${`1fr ${theme.space[4]} 1fr ${theme.space[4]} 1fr`};
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${theme.space[4]};

  padding: ${theme.space[8]};

  ${theme.below.md} {
    padding: 32px 12px;
  }

  ${theme.below.sm} {
    -ms-grid-columns: 1fr 12px 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
  }

  p {
    font-family: ${theme.fonts.primary}, sans-serif;
    font-style: normal;
    font-weight: ${theme.fontWeights.light};
    font-size: ${theme.fontSizes[3]};
    line-height: 161%;

    letter-spacing: 0.2px;

    ${theme.below.sm} {
      font-size: ${theme.fontSizes[2]};
      margin-bottom: 1rem;
    }
  }
`;

const PositionedCircle = styled('div')`
  width: 100%;

  ${theme.above.sm} {
    position: absolute;
    top: 0.8rem;
    right: 1.6rem;
    width: 15%;
    z-index: 2;
  }

  ${theme.above.md} {
    top: 2rem;
    right: 4rem;
  }
`;

const ColumnSpaceBetween = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${theme.below.sm} {
    margin-bottom: ${theme.space[3]};
  }
`;

const StyledLink = styled(Link)`
  color: ${theme.colors.black};
`;

const CampaignCircle = () => (
  <PositionedCircle>
    <CircleBadge color={theme('colors.blue')} text={t('Campaign!')} />
  </PositionedCircle>
);

const Campaign = ({ title, text, url, buttonText, children }) => (
  <Container>
    <MaxWidth>
      <Grid>
        <ColumnSpaceBetween>
          <div>
            <Title>{title}</Title>
            <p>{text}</p>
          </div>
          {url && <StyledLink to={url}>{buttonText}</StyledLink>}
        </ColumnSpaceBetween>
        <CampaignCircle />
        {children}
      </Grid>
    </MaxWidth>
  </Container>
);

export default Campaign;
