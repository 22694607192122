import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/';
import React from 'react';
import { styled } from 'linaria/react';
import { buttonStyle } from '../../ui/Button';
import cartQuery from '../../Cart/CartQuery.gql';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { theme } from '../../Theme';

const Container = styled('aside')`
  ${theme.above.sm} {
    width: 320px;
  }
  ${theme.below.sm} {
    width: 100%;
  }
  background-color: ${theme.colors.white};
`;

const CheckoutButton = styled('a')`
  ${buttonStyle}
  padding: ${theme.space[2]};
  text-align: center;
  background: #911827;
  font-family: ${theme.fonts.primary};
  color: #fff;
  width: 100%;
  line-height: 14px;
  border-radius: none !important;
`;

const ProductImageWrapper = styled('div')`
  width: 5rem;
  margin-right: 1rem;
`;

const ProductCheckoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  a {
    text-decoration: none;
    :hover {
      opacity: 0.8;
      text-decoration: none;
      color: white;
    }
  }
`;

const Product = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: ${theme.space[2]};
`;

const ProductDetails = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: black;
  p {
    margin: 0;
  }
`;

const Header = styled('h3')`
  padding: ${theme.space[2]} 0;
  margin: 0 ${theme.space[2]};
  border-bottom: 1px solid #e0e0e0;

  font-family: ${theme.fonts.primary};
  font-weight: 700;
  font-size: ${theme.fontSizes[0]};
  color: #111;
  text-transform: uppercase;
  letter-spacing: 0.6px;
`;

const ProductName = styled('p')`
  margin-top: 4px;
  font-size: ${theme.fontSizes[0]};
  font-weight: 700;
`;

const ProductDescription = styled('p')`
  margin-top: 8px;
  font-size: 12px;
  font-weight: ${theme.fontWeights.light};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 220px;
`;

const Error = styled('p')`
  color: red;
  margin-top: ${theme.space[1]};
  font-size: ${theme.fontSizes[0]};
`;

const ProductToast = ({
  product,
  cart,
  closeToast,
  error,
  selectedVariation,
}) => {
  const track = useTracker();
  const image = selectedVariation?.images[0] || product.images[0];

  return (
    <Container>
      <ProductCheckoutContainer>
        <Header>
          {error ? t('Failed adding to cart') : t('Added to cart')}
        </Header>
        <Product>
          {product.images.length > 0 && (
            <ProductImageWrapper>
              <Image
                src={image?.url}
                sizes={80}
                aspect={'1:1'}
                alt={product?.images[0]?.alt}
              />
            </ProductImageWrapper>
          )}
          <ProductDetails>
            <ProductName>{product.name}</ProductName>
            {!error && !product?.isPackage && (
              <ProductDescription>
                {product.shortDescription}
              </ProductDescription>
            )}
            {error && product.isPackage ? (
              <Error>
                {t('An error occurred. Details:')}

                <ul>
                  {error?.messages?.length > 0 ? (
                    error?.messages?.map((message) => (
                      <li key={message}>{t(message)}</li>
                    ))
                  ) : (
                    <li>{t(error?.message)}</li>
                  )}
                </ul>
              </Error>
            ) : (
              error && (
                <Error>
                  {t('An error occurred. Details:')}
                  <ul>
                    {error?.graphQLErrors &&
                    error?.graphQLErrors?.length > 0 ? (
                      error?.graphQLErrors?.map(
                        ({ message, locations, path }) => (
                          <li key={message}>{t(message)}</li>
                        )
                      )
                    ) : (
                      <li>{t(error?.message)}</li>
                    )}
                  </ul>
                </Error>
              )
            )}
          </ProductDetails>
        </Product>
        {cart && cart.externalCheckoutUrl && (
          <ChannelContext.Consumer>
            {({ selectedChannel }) => (
              <CheckoutButton
                href={`${cart.externalCheckoutUrl}&channelCountry=${selectedChannel.country.code}`}
                onClick={() => {
                  track(trackCartCheckoutEvent({ cart }));
                }}
              >
                {t('To checkout')}
              </CheckoutButton>
            )}
          </ChannelContext.Consumer>
        )}
      </ProductCheckoutContainer>
    </Container>
  );
};

const ProductToastWrapper = (props) => (
  <CartProvider query={cartQuery}>
    {({ data }) => <ProductToast {...props} cart={data && data.cart} />}
  </CartProvider>
);

export default ProductToastWrapper;
