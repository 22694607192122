import React from 'react';
import Image from '@jetshop/ui/Image/Image';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import { LinkEvaluatorExternal } from '../../utils/LinkEvaluatorExternal';
import HeroProductCard from '../Content/HeroProductCard';

const ImageBoxWrapper = styled('div')`
  flex: 1;
  width: 100%;
  height: 100%;

  :hover {
    cursor: pointer;
    .light {
      background: ${theme.colors.hoverRed};
      color: #fff;
    }
    .dark {
      background: ${theme.colors.darkRed};
      color: #fff;
    }
  }

  [data-flight-image-children] {
    z-index: unset !important;
    position: absolute !important;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
  }
`;

const ContentWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px;
  color: ${(props) => props.color};

  p {
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    text-align: center;
    padding: 3px;
  }

  &.TOP {
    align-self: flex-start;
  }
  &.MIDDLE {
    align-self: center;
  }
  &.BOTTOM {
    align-self: flex-end;
  }
  &.LEFT {
    align-items: flex-start;
    text-align: left;
  }
  &.CENTER {
    align-items: center;
    text-align: center;
  }
  &.RIGHT {
    align-items: flex-end;
    text-align: right;
  }
`;

const CTA = styled('div')`
  text-decoration: none;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.1em;
  font-weight: 700;
  color: #fff;
  border-radius: 15px;
  padding: 0 2.5rem;
  &.light {
    background: ${theme.colors.darkRed};
  }
  &.dark {
    background: ${theme.colors.hoverRed};
  }
  &.small {
    height: 24px;
    width: fit-content;
    padding: 0 1.3rem;
  }
`;

//Imagebox
export const ImageBoxItem = ({
  imageUrl,
  title,
  smallTitle,
  contentVerticalAlignment,
  contentHorizontalAlignment,
  color,
  link,
  buttonText,
  buttonTheme,
  buttonSize,
  position,
}) => {
  const vertAlign = contentVerticalAlignment;
  const horAlign = contentHorizontalAlignment;

  return (
    <ImageBoxWrapper
      className={cx('position' + position?.value, 'imagebox-wrapper')}
    >
      <LinkEvaluatorExternal link={link}>
        <Image
          className={cx(vertAlign?.value, horAlign?.value)}
          cover={true}
          src={imageUrl?.value}
          aspect={null}
          critical
        >
          <ContentWrapper
            className={cx(vertAlign?.value, horAlign?.value)}
            color={color?.value ?? 'transparent'}
          >
            {title?.value && (
              <h2
                dangerouslySetInnerHTML={{
                  __html: title?.value,
                }}
              ></h2>
            )}
            {smallTitle?.value && (
              <h3
                dangerouslySetInnerHTML={{
                  __html: smallTitle?.value,
                }}
              ></h3>
            )}
            {buttonText?.value && (
              <CTA
                className={cx(
                  buttonTheme?.value,
                  horAlign?.value,
                  buttonSize?.value
                )}
              >
                <span>{buttonText?.value}</span>
              </CTA>
            )}
          </ContentWrapper>
        </Image>
      </LinkEvaluatorExternal>
    </ImageBoxWrapper>
  );
};

const TextBoxWrapper = styled('div')`
  flex: 1;
  width: 100%;
  background: ${(props) => props.background};
  padding: ${(props) => props.padding};
  color: ${(props) => props.color};
  p {
    margin-bottom: 30px;
    font-size: 15px;
  }
  .content-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
  }

  a {
    text-decoration: none;
  }

  :hover {
    .CTA {
      cursor: pointer;
      .light {
        background: ${theme.colors.hoverRed};
        color: #fff;
      }
      .dark {
        background: ${theme.colors.darkRed};
        color: #fff;
      }
    }
  }
`;

//Textbox
export const TextBoxItem = ({
  title,
  smallTitle,
  contentVerticalAlignment,
  contentHorizontalAlignment,
  color,
  link,
  buttonText,
  buttonTheme,
  buttonSize,
  background,
  padding,
  position,
}) => {
  const vertAlign = contentVerticalAlignment;
  const horAlign = contentHorizontalAlignment;

  return (
    <TextBoxWrapper
      className={cx('position' + position?.value, 'textbox-wrapper')}
      background={background?.value ?? 'transparent'}
      color={color?.value ?? '#000'}
      padding={padding?.value ?? '0'}
    >
      <LinkEvaluatorExternal link={link}>
        <div className="content-wrapper">
          <ContentWrapper
            className={cx(vertAlign?.value, horAlign?.value)}
            color={color?.value ?? 'transparent'}
          >
            {title?.value && (
              <h2
                dangerouslySetInnerHTML={{
                  __html: title?.value,
                }}
              ></h2>
            )}
            {smallTitle?.value && (
              <h3
                dangerouslySetInnerHTML={{
                  __html: smallTitle?.value,
                }}
              ></h3>
            )}
            {buttonText?.value && (
              <CTA
                className={cx(
                  'CTA',
                  buttonTheme?.value,
                  horAlign?.value,
                  buttonSize?.value
                )}
              >
                <span>{buttonText?.value}</span>
              </CTA>
            )}
          </ContentWrapper>
        </div>
      </LinkEvaluatorExternal>
    </TextBoxWrapper>
  );
};

const ProductBoxWrapper = styled('div')`
  min-width: 30%;
  display: flex;
  flex-direction: column;
  div {
    a {
      height: 100%;
    }
  }
`;

//Productbox
export const ProductBoxItem = ({ product, position }) => {
  return (
    <ProductBoxWrapper
      className={cx('position' + position?.value, 'product-wrapper')}
    >
      <HeroProductCard product={product?.value} />
    </ProductBoxWrapper>
  );
};
