import React, { memo } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import Image from '@jetshop/ui/Image';
import { Link } from 'react-router-dom';
import { theme } from '../Theme';

const ImageLinksRowWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 10px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE10+ */

  &::-webkit-scrollbar {
    display: none;
  }

  &.center {
    margin: 20px auto;
    justify-content: center;

    ${theme.below.lg} {
      justify-content: flex-start;
    }
  }

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  &.round {
    .image-wrapper {
      border-radius: 50%;
      overflow: hidden; /* Makes sure the rounding is applied correctly */
    }
  }

  &.some {
    .image-wrapper {
      border-radius: 5px;
      overflow: hidden;
    }
  }

  &.none {
    .image-wrapper {
      border-radius: 0;
    }
  }
`;

const ImageLinksRowItemWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 6px;
  cursor: pointer;
  text-decoration: none;
  color: #000;

  &:hover {
    .image-wrapper {
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    }
    .title {
      text-decoration: underline;
    }
  }

  .image-wrapper {
    height: 100px;
    width: 100px;
    padding: 10px;
    background: #f5f3f5;

    img {
      mix-blend-mode: multiply;
    }
  }

  .title {
    font-size: 12px;
    line-height: 1.3;
    margin-top: 8px;
    max-width: 100px;
  }
`;

export const ImageLinksRowItem = memo(({ imageUrl, title, link }) => {
  return (
    <ImageLinksRowItemWrapper to={link?.value}>
      <div className="image-wrapper">
        <Image src={imageUrl?.value} aspect="1:1" alt={title?.value} />
      </div>
      <div className="title">{title?.value}</div>
    </ImageLinksRowItemWrapper>
  );
});

export const ImageLinksRow = memo(({ children, alignment, imageRounding }) => {
  return (
    <ImageLinksRowWrapper
      className={cx(imageRounding?.value, alignment?.value)}
    >
      {children}
    </ImageLinksRowWrapper>
  );
});
