import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import Image from '@jetshop/ui/Image/';
import { LinkEvaluatorExternal } from '../../utils/LinkEvaluatorExternal';

const ContentRowWrapper = styled('section')`
  padding: 4rem 4rem 6.5rem 4rem;
  display: flex;
  flex-direction: column;
  h1,
  h2 {
    font-family: ${theme.fonts.primary};
    font-size: 28px;
    line-height: 34px;
    font-weight: 500;
    margin: 16px 0;
    &.left {
      text-align: left;
    }
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }

    ${theme.below.lg} {
      padding: 0 16px;
    }
  }

  .link-evaluator {
    font-size: 14px;
    color: black;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;
    letter-spacing: 0.2px;
  }

  ${theme.below.lg} {
    padding: 0;
  }
`;
const ContentRowItemsWrapper = styled('div')``;

const ContentRowItemsInnerWrapper = styled('div')`
  display: flex;
  flex-direction: ${(props) => props?.reverted};
  height: 100%;
  ${'' /* padding: 4rem 4rem 6.5rem 4rem; */}
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
  padding-top: ${(props) => props.paddingtop}px;
  padding-bottom: ${(props) => props.paddingbottom}px;
  margin-top: ${(props) => props.margintop}px;
  margin-bottom: ${(props) => props.marginbottom}px;

  ${theme.below.lg} {
    padding: 0;
    margin: 0;
    flex-direction: column;
  }
`;

const ImageWrapper = styled('div')`
  flex: 1;
  margin-right: 24px;
  width: 100%;
  ${theme.below.lg} {
    margin: 0;
  }

  &.reverted {
    margin-left: 24px;
    margin-right: 0;
    ${theme.below.lg} {
      margin: 0;
    }
  }
`;

const TextWrapper = styled('div')`
  flex: 1;
  color: ${(props) => props.foregroundcolor};
  padding: 2rem;

  ${theme.below.lg} {
    padding: 0;

    p {
      margin-left: 0 !important;
      padding: 0 16px;
    }
    h2 {
      margin-left: 0 !important;
    }
  }

  .link-evaluator {
    color: inherit;
  }

  p {
    text-align: left;
    font-size: 14px;
    font-family: Lato, sans-serif;
    font-weight: 300;
    letter-spacing: 0.2px;
    line-height: 1.5;
    margin-bottom: 1rem;
    margin-left: 30px;
  }

  h2 {
    margin-left: 30px;
  }

  &.reverted {
    h2,
    p {
      margin-left: 0;
      margin-right: 30px;
    }
    ${theme.below.lg} {
      h2 {
        margin-right: 0 !important;
      }
    }
  }
`;

const LinksWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  padding-top: 30px;

  &.reverted {
    margin-left: 0;
    margin-right: 30px;
  }
`;

export const ContentRowItem = ({
  imageSrc,
  title,
  text,
  reverted,
  backgroundColor,
  firstLinkText,
  firstLink,
  seccondLinkText,
  seccondLink,
  thirdLinkText,
  thirdLink,
  titleAlignment,
  imageRatio,
  imageWrapperPaddingTop,
  imageWrapperPaddingBottom,
  imageWrapperMarginTop,
  imageWrapperMarginBottom,
  textColor,
}) => {
  return (
    <ContentRowItemsInnerWrapper
      reverted={reverted?.value === true ? 'row-reverse' : 'row'}
      background={backgroundColor?.value ?? '#fff'}
      paddingtop={
        isNaN(parseInt(imageWrapperPaddingTop?.value))
          ? '64'
          : parseInt(imageWrapperPaddingTop?.value)
      }
      paddingbottom={
        isNaN(parseInt(imageWrapperPaddingBottom?.value))
          ? '64'
          : parseInt(imageWrapperPaddingBottom?.value)
      }
      margintop={
        isNaN(parseInt(imageWrapperMarginTop?.value))
          ? '104'
          : parseInt(imageWrapperMarginTop?.value)
      }
      marginbottom={
        isNaN(parseInt(imageWrapperMarginBottom?.value))
          ? '64'
          : parseInt(imageWrapperMarginBottom?.value)
      }
    >
      <ImageWrapper className={reverted?.value ? 'reverted' : ''}>
        <Image src={imageSrc?.value} cover={true} aspect={imageRatio?.value} />
      </ImageWrapper>

      <TextWrapper
        foregroundcolor={textColor?.value ?? '#000'}
        className={reverted?.value ? 'reverted' : ''}
      >
        <h2 className={titleAlignment?.value}>{title?.value}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: text?.value,
          }}
        />
        <LinksWrapper className={reverted?.value ? 'reverted' : ''}>
          {firstLink?.value && (
            <LinkEvaluatorExternal link={firstLink}>
              {firstLinkText?.value}
            </LinkEvaluatorExternal>
          )}
          {seccondLink?.value && (
            <LinkEvaluatorExternal to={seccondLink?.value}>
              {seccondLinkText?.value}
            </LinkEvaluatorExternal>
          )}
          {thirdLink?.value && (
            <LinkEvaluatorExternal to={thirdLink?.value}>
              {thirdLinkText?.value}
            </LinkEvaluatorExternal>
          )}
        </LinksWrapper>
      </TextWrapper>
    </ContentRowItemsInnerWrapper>
  );
};

export const ContentRow = ({ title, children }) => {
  return (
    <ContentRowWrapper className="hideInPrint content-row-wrapper">
      {title?.value && <h1>{title?.value}</h1>}
      <ContentRowItemsWrapper className="hideInPrint">
        {children}
      </ContentRowItemsWrapper>
    </ContentRowWrapper>
  );
};
