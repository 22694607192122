import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

import Image from '@jetshop/ui/Image/';
import { theme } from '../Theme';
import CircleBadge from '../ui/CircleBadge';

const SlideContainer = styled(Link)`
  position: relative;

  height: 80vh;
  max-height: 32rem;

  .slide-wrap {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, transparent 50%);
    width: 100%;
    height: 100%;
  }
`;

const SlideContent = styled('div')`
  position: absolute;
  bottom: 64px;
  left: 48px;
  padding-right: 48px;

  ${theme.below.md} {
    left: 16px;
    bottom: 48px;
  }

  > h2 {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 40px;
    letter-spacing: 0.2px;
    color: ${theme.colors.white};
    text-transform: uppercase;
    margin-bottom: 12px;

    ${theme.below.md} {
      font-size: 28px;
    }
  }

  p {
    font-family: 'Lato';
    font-weight: 300;
    font-size: 22px;
    letter-spacing: 0.2px;
    color: ${theme.colors.white};

    ${theme.below.md} {
      font-size: 16px;
      line-height: 29px;
    }
  }
`;

const PositionedCircle = styled('div')`
  width: 200px;
  position: absolute;
  top: 40px;
  right: 72px;

  ${theme.below.md} {
    width: 35%;
    max-width: 200px;
    top: 32px;
    right: 16px;
  }
`;

const Slide = ({ image, title, text, circle, link, style }) => {
  const [isDragging, setIsDragging] = useState(false);

  return (
    <SlideContainer
      style={style}
      to={link}
      onDragStart={(e) => {
        setIsDragging(true);
        e.preventDefault();
      }}
      onClick={(e) => {
        if (isDragging) {
          if (e) {
            e.preventDefault();
          }
          setIsDragging(false);
        }
      }}
    >
      <Image src={image} fillAvailableSpace>
        <div className="slide-wrap">
          <SlideContent>
            <h2>{title}</h2>
            <p>{text}</p>
          </SlideContent>
          {circle && circle.length > 0 && (
            <PositionedCircle>
              <CircleBadge color={theme('colors.orange')} text={circle} />
            </PositionedCircle>
          )}
        </div>
      </Image>
    </SlideContainer>
  );
};
export default Slide;
