import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/Image';
import CustomLink from '../ui/CustomLink';

import { theme } from '../Theme';

const FullWidthImage = styled(Image)`
  width: 100%;
`;

const PhotoText = styled('p')`
  margin-top: 16px;

  font-size: ${theme.fontSizes[0]};
  font-family: ${theme.fonts.primary}, sans-serif;
  color: #b6b6b6;
  letter-spacing: 0.2px;
`;

const Photo = ({ url, text, aspect, link }) => (
  <>
    <CustomLink link={link}>
      <FullWidthImage src={url} aspect={aspect} cover />
    </CustomLink>
    {text && <PhotoText>{text}</PhotoText>}
  </>
);

export default Photo;
