import React from 'react';
import { styled } from 'linaria/react';

import RemoveFromCart from '@jetshop/core/components/Mutation/RemoveFromCart';
import useSetQuantity from '@jetshop/core/components/Mutation/useSetQuantity';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image/';
import { Price } from '@jetshop/ui/Price';
import ProductLink from '@jetshop/ui/ProductLink';
import cartQuery from './CartQuery.gql';
import setQuantityMutation from './SetQuantityMutation.gql';

import { ReactComponent as Trash } from '../../svg/Trash.svg';
import { ReactComponent as Plus } from '../../svg/Plus.svg';
import { ReactComponent as Minus } from '../../svg/Minus.svg';

import { theme } from '../Theme';

const Wrapper = styled('div')`
  display: flex;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  > :first-child {
    flex: 1 1 40%;
    ${theme.below.sm} {
      flex: 1 1 35%;
    }
  }
  > :last-child {
    flex: 1 1 60%;
    padding: 0 0.5rem;
    ${theme.below.sm} {
      flex: 1 1 65%;
    }
  }
`;

const ProductName = styled('div')`
  h2 {
    font-size: 14px;
    font-weight: 700;
    line-height: 179%;
    text-transform: uppercase;

    color: #111;
    letter-spacing: 0.2px;
    margin: 0;
  }

  h3 {
    font-size: 14px;
    font-weight: ${theme.fontWeights.light};
    color: ${theme.colors.black};
    letter-spacing: 0.2px;
    margin: 0;
  }
`;

const ProductDetail = styled('section')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const LinePrice = styled(Price)`
  position: absolute;
  right: 0;
  bottom: 6px;
  ${theme.below.sm} {
    bottom: 3px;
  }

  ${Price.Normal} {
    font-size: 14px;
    line-height: 179%;

    text-align: right;

    color: ${theme.colors.black};
  }

  label {
    font-size: 14px;
    line-height: 179%;

    color: ${theme.colors.black};
  }
`;

const AdjustQty = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 12px;
  line-height: 164.06%;
  margin-top: 1rem;

  > p {
    margin-bottom: 0.5rem;
  }
  /* Plus/minus buttons */
  button {
    width: 32px;
    height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #000000;
    background-color: white;
    border-radius: 50%;

    &:hover {
      background-color: ${theme.colors.notWhite};
    }
  }

  /* Count */
  span {
    display: inline-block;
    width: 32px;
    padding: 0.3rem;
    margin: 0 0.4rem;

    border: 1px solid #e0e0e0;
    border-radius: 4px;
    text-align: center;
    color: #000000;
    ${theme.below.sm} {
      padding: 0.25rem;
    }
  }
`;

const RemoveItem = styled('div')`
  position: absolute;
  top: -4px;
  right: -4px;

  button {
    width: 32px;
    height: 32px;
    border-radius: 50%;

    outline: none;
    border: none;
    margin-left: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    &:hover {
      background-color: ${theme.colors.notWhite};
    }
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const ImageWrapper = styled('div')`
  ${theme.below.sm} {
    flex: 1 1 75%;
  }
`;

const CartItem = ({ item, className = '', hideTarget }) => {
  const { isVariant, variantImage, variantValues, hasVariantImage } =
    getCartItemVariant(item);

  const { setQuantity } = useSetQuantity({ cartQuery, setQuantityMutation });
  const { distributionPackageSize } = item.product;

  let decreasedQuantity = item.quantity - distributionPackageSize;
  if (decreasedQuantity < distributionPackageSize)
    decreasedQuantity = distributionPackageSize;

  const increasedQuantity = item.quantity + distributionPackageSize;

  return (
    <Wrapper className={className}>
      <ProductLink product={item.product} onClick={hideTarget}>
        <ImageWrapper>
          {item?.product?.images?.length > 0 && (
            <Image
              src={
                hasVariantImage
                  ? variantImage?.url
                  : item?.product?.images[0]?.url
              }
              alt={
                hasVariantImage
                  ? variantImage?.alt
                  : item?.product?.images[0]?.alt
              }
              aspect="1:1"
              cover
            />
          )}
        </ImageWrapper>
      </ProductLink>
      <ProductDetail>
        <ProductLink
          product={item.product}
          onClick={hideTarget}
          style={{ textDecoration: 'none' }}
        >
          <ProductName>
            <h2>{item.product.name}</h2>
            <h3>{item.subHeading}</h3>
            {isVariant && <h3>{variantValues.join(', ')}</h3>}
          </ProductName>
        </ProductLink>

        <RemoveItem>
          <RemoveFromCart cartQuery={cartQuery}>
            {(removeFromCart) => (
              <button
                onClick={() => removeFromCart(item.id, item.product)}
                data-testid="remove-from-cart"
              >
                <Trash />
              </button>
            )}
          </RemoveFromCart>
        </RemoveItem>

        <AdjustQty>
          <button
            disabled={item.quantity === distributionPackageSize}
            onClick={() =>
              setQuantity({ itemId: item.id, quantity: decreasedQuantity })
            }
          >
            <Minus />
          </button>
          <span>{item.quantity}</span>
          <button
            onClick={() =>
              setQuantity({ itemId: item.id, quantity: increasedQuantity })
            }
          >
            <Plus />
          </button>
        </AdjustQty>

        <LinePrice price={item.total} />
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
