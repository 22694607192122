import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { Intl } from '@jetshop/intl';
import { ReactComponent as MobelmastarnasLogos } from '../../svg/Logo.svg';

const SvgWrapper = styled('div')`
  /* max-width: 133px;
  width: 67px; */
  width: ${(props) => props.size}px;
`;

const Logo = ({ size }) => {
  return (
    <div>
      <Intl>
        {(t) => (
          <Link to="/" aria-label={t('Möbelmästarnas startsida')}>
            <SvgWrapper size={size}>
              <MobelmastarnasLogos />
            </SvgWrapper>
          </Link>
        )}
      </Intl>
    </div>
  );
};

export default Logo;
