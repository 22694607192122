import React from 'react';
import { Link } from 'react-router-dom';

export const LinkEvaluatorExternal = ({ link, children }) => {
  const isExternal = link?.value?.includes('https');

  if (link?.value) {
    return isExternal ? (
      <a href={link?.value} className={'external-link link-evaluator'}>
        {children}
      </a>
    ) : (
      <Link to={link?.value} className={'internal-link link-evaluator'}>
        {children}
      </Link>
    );
  }

  return (
    <div style={{ height: '100%' }} className={'no-link link-evaluator'}>
      {children}
    </div>
  );
};
