import { default as React } from 'react';
import MenuContainer from './ThreeTierMenu/MenuContainer';

function MainMenu({ categories, setMenuOverlay }) {
  return (
    <nav>
      <MenuContainer
        queryData={categories.data}
        setMenuOverlay={setMenuOverlay}
      />
    </nav>
  );
}

export default MainMenu;
