import React from 'react';
import { styled } from 'linaria/react';
import { Price } from '../Price';
import { cx } from 'linaria';
import { theme } from '../Theme';

const MastarPrisWrapper = styled('div')`
  &.productcard {
    .header {
      display: flex;
    }
    h3 {
      margin-top: 0;
      text-transform: uppercase;
      color: #ef843c;
      font-weight: 700;
      font-size: 12px;
    }
    > div {
      margin-top: 0 !important;
    }
  }

  .header {
    display: flex;
  }
  h3 {
    margin: 0;
    /* margin-top: 1rem; */
    text-transform: uppercase;
    color: #ef843c;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
  }
  > div {
    margin-top: 5px !important;
    line-height: 30px;
  }
  .new-price,
  .mastarpris {
    color: #ef843c !important;
  }
  .old-price {
    text-decoration: line-through;
  }
`;

const Tooltip = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -7px;
  margin-left: 7px;
  &.productcard {
    z-index: 1;
    margin-top: 0;
    width: 10px;
    height: 10px;
    font-size: 8px;
    padding-left: 4px;
    /* margin-top: 8px !important; */
    margin-left: 3px !important;

    ${theme.above.lg} {
      &:hover {
        div {
          transform: scale(1);
        }
      }
    }
  }

  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 10px;
  background: black;
  color: white;
  position: relative;
  cursor: pointer;

  span {
    line-height: 10px;
    font-size: 10px;
    color: white;
    font-weight: 600;
    margin-bottom: 2px;
  }

  div {
    color: #000;
    transition: 0.3s;
    transform-origin: left bottom;
    transform: scale(0);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f3f3f3;
    position: absolute;
    bottom: 18px;
    left: 12px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 1;

    width: 400px;
    ${theme.below.md} {
      width: 200px;
    }
  }

  &:hover {
    div {
      transform: scale(1);
    }
  }

  p {
    padding: 20px;
    font-size: 12px;
    line-height: 18px;
  }

  &.productcard {
    margin-top: 0;
    width: 11px;
    height: 11px;
    font-size: 8px;
    padding-left: 0px;

    ${theme.above.lg} {
      &:hover {
        div {
          transform: scale(1);
        }
      }
    }
  }
`;

const InnerWrapper = styled('div')`
  display: flex;
  margin-right: 0.5rem;
`;

export const MastarPrisComponent = ({
  selectedVariation,
  product,
  productcard,
}) => {
  const differentPrice = product.previousPrice.incVat !== product.price.incVat;

  return (
    <>
      {productcard ? (
        <MastarPrisWrapper className={productcard ? 'productcard' : null}>
          <div className="header">
            <InnerWrapper>
              <Price
                className="new-price"
                price={(selectedVariation || product)?.price}
              />
              <Tooltip
                className={cx('tooltip', productcard ? 'productcard' : null)}
              >
                <span>i</span>
                <div>
                  <p>
                    <strong>Mästarpriser </strong>
                    på Möbelmästarna är extra fördelaktiga priser som vi
                    förhandlat fram för våra kunder. priserna är lägre än de
                    rekommenderade priserna från leverantören men är inte
                    rabatterade priser. mästarpriser är fasta och inte
                    tidsbegränsade. Även våra mästarpriser kan påverkas av
                    prisändringar och yttre faktorer. Skillnaden är att
                    Mästarpriser inte är relaterade till kampanjer eller är att
                    ses som ett rabatterat pris.
                  </p>
                </div>
              </Tooltip>
            </InnerWrapper>
            {differentPrice && (
              <Price
                className="old-price"
                price={(selectedVariation || product)?.previousPrice}
              />
            )}
          </div>
        </MastarPrisWrapper>
      ) : (
        <MastarPrisWrapper className="mastarpris">
          <div className="header">
            <h3>Mästarpris</h3>
            <Tooltip>
              <span>i</span>
              <div>
                <p>
                  <strong>Mästarpriser </strong>
                  på Möbelmästarna är extra fördelaktiga priser som vi
                  förhandlat fram för våra kunder. priserna är lägre än de
                  rekommenderade priserna från leverantören men är inte
                  rabatterade priser. mästarpriser är fasta och inte
                  tidsbegränsade. Även våra mästarpriser kan påverkas av
                  prisändringar och yttre faktorer. Skillnaden är att
                  Mästarpriser inte är relaterade till kampanjer eller är att
                  ses som ett rabatterat pris.
                </p>
              </div>
            </Tooltip>
          </div>

          <Price
            className="mastarpris"
            previousPrice={(selectedVariation || product).previousPrice}
            price={(selectedVariation || product).price}
          />
        </MastarPrisWrapper>
      )}
    </>
  );
};
