import React from 'react';
import { styled } from 'linaria/react';
import { withRouter } from 'react-router';

const InfoBarContainer = styled('div')`
  ul {
    height: 25px;
    margin-top: 0.25rem;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-end;
  }

  li {
    height: inherit;
    display: inline-flex;
    align-items: center;

    a {
      margin: 0 12px;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      text-decoration: none;
      color: #b6b6b6;

      &:hover {
        color: #333333;
        text-decoration: none;
      }
    }
  }
`;

const goToPage = (e, history) => {
  e.preventDefault();
  const url = e.target.getAttribute('href');

  if (url.indexOf('http') !== -1) {
    window.open(url, '_newtab');
  } else {
    history.push({
      pathname: url,
      state: {
        type: 'page',
      },
    });
  }
};

const InfoBar = ({ history, pageLinks }) =>
  pageLinks ? (
    <InfoBarContainer>
      <div
        onClick={(e) => goToPage(e, history)}
        dangerouslySetInnerHTML={{ __html: pageLinks?.header?.content }}
      />
    </InfoBarContainer>
  ) : null;

export default withRouter(InfoBar);
