import React from 'react';
import { styled } from 'linaria/react';
import ProductSpecifications, {
  Table,
} from '@jetshop/ui/ProductSpecifications/ProductSpecifications';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import FileIcon from '../../svg/File.svg';
import { theme } from '../Theme';
import { cx } from 'linaria';
import { DownloadableFiles } from './DownloadableFiles';

const BaseStyles = `
  color: #111;
  font-weight: ${theme.fontWeights.regular};
  font-size: 14px;
  line-height: 2em;
  letter-spacing: 0.2px;
`;

const ProductInfoWrapper = styled('div')`
  margin-bottom: 2rem;
  width: 100%;
`;

const ProductInfoHeader = styled('h2')`
  width: 100%;
  margin: 2.5rem 0;
  padding-bottom: 1rem;
  font-size: ${theme.fontSizes[4]};
  font-weight: ${theme.fontWeights.light};
  letter-spacing: 0.2px;
  color: ${theme.colors.black};
`;

export const ProductSection = styled('section')`
  margin: 0;
  width: 100%;
  padding-bottom: 3rem;

  .custom-badges {
    p {
      font-weight: 800 !important;
      font-size: 10px !important;
    }
  }

  &.below {
    ${theme.above.md} {
      margin-right: 2rem;
      margin-left: 2rem;
    }
  }

  h3 {
    margin: 0;
  }

  ${theme.above.md} {
    p {
      font-weight: ${theme.fontWeights.regular} !important;
      font-size: 14px !important;
      line-height: 2em !important;
      letter-spacing: 0.2px !important;
    }
    padding-bottom: 1rem;
    /* &.fullWidth { */
    width: calc(50% - 4rem);

    &:nth-child(odd) {
      margin-right: 2rem;
    }

    &:nth-child(even) {
      margin-left: 2rem;
    }
    /* } */
  }

  ${Table} {
    table-layout: auto;

    tr th {
      vertical-align: top;
      padding-right: 5rem;

      ${theme.above.md} {
        padding-right: 3rem;
      }
    }
    tr {
      &:nth-child(odd) {
        background: #f7f7f7;
      }
      th,
      td {
        padding: 0.5rem;
        border: none;
      }
    }

    tr td {
      width: 100%;
      ul {
        padding: 0;
      }

      ul,
      li {
        list-style: none;
        margin: 0;
      }
    }

    th {
      width: 38%;
      white-space: nowrap;

      ${theme.below.md} {
        width: 60%;
      }
    }

    tr > * {
      ${BaseStyles};
      ${theme.above.md} {
        font-weight: ${theme.fontWeights.regular} !important;
        font-size: 14px !important;
        line-height: 2em !important;
        letter-spacing: 0.2px !important;
      }
    }
  }
`;

export const SectionHeading = styled('h3')`
  color: #000;
  font-family: ${theme.fonts.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 199%; /* 31.84px */
  cursor: pointer;

  .reviews-ratings {
    margin-left: 10px;
  }
`;

const Documents = styled('div')`
  ${BaseStyles};
  line-height: 2.2em;

  a {
    display: flex;
    align-items: center;
    color: inherit;

    &::before {
      content: '';
      display: block;
      background: url(${FileIcon}) no-repeat;
      width: 19px;
      height: 19px;
      float: left;
      margin: 0 8px 0 0;
    }
  }
`;

const Description = styled('article')`
  ${BaseStyles};
`;

const ProductInfo = ({ product }) => {
  const excludes = [
    'Mastarpris',
    'extracontent',
    'configurator',
    'store',
    'files',
    'Upsell',
    'newConfigurator',
  ];
  const productCustomFieldsAdjusted = product?.customFields
    ?.map((customfield) => {
      if (customfield.key == 'BenFotUnderrede') {
        return { ...customfield, title: 'Ben / Fot / Underrede' };
      }
      return { ...customfield, title: customfield.title.replace(/_/g, ' ') };
    })
    .filter((field) => !excludes.includes(field.key));
  const files = product?.customFields?.find(
    ({ key }) => key === 'files'
  )?.listValues;

  return (
    <ProductInfoWrapper className="avoidCuttingInPrint marginTopInPrint">
      <Above breakpoint="md">
        {(matches) =>
          matches ? (
            <ProductInfoHeader>{t('Product details')}</ProductInfoHeader>
          ) : (
            <ProductSection fullWidth className={'fullWidth'}>
              <SectionHeading>{t('Description')}</SectionHeading>
              <Description
                dangerouslySetInnerHTML={{
                  __html: product.shortDescription,
                }}
              />
            </ProductSection>
          )
        }
      </Above>

      {product?.customFields?.length > 0 && (
        <ProductSection fullWidth className={cx('fullWidth', 'below')}>
          <SectionHeading>{t('Specifications')}</SectionHeading>
          <ProductSpecifications fields={productCustomFieldsAdjusted} />
        </ProductSection>
      )}

      {product.description && (
        <ProductSection
          fullWidth
          className={cx('fullWidth', 'below')}
          id="description"
        >
          <SectionHeading>{t('Documents')}</SectionHeading>
          <Documents
            dangerouslySetInnerHTML={{
              __html: product.description,
            }}
          />
        </ProductSection>
      )}

      {files?.length > 0 && (
        <ProductSection className="files hideInPrint">
          <DownloadableFiles files={files} />
        </ProductSection>
      )}
    </ProductInfoWrapper>
  );
};

export default ProductInfo;
