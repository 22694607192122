import * as React from 'react';
// import AnimateHeight from 'react-animate-height';

// AccordionContent component converted to use JSX syntax
export const AccordionContent = (props) => {
  if (props.isOpen) {
    return props.children || <React.Fragment />;
  }

  return null;
};
