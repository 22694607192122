import t from '@jetshop/intl';
import React from 'react';
import { styled } from 'linaria/react';

import TitleWithLines from '../ui/TitleWithLines';
import MaxWidth from '../Layout/MaxWidth';

import { theme } from '../Theme';

const Container = styled('div')`
  width: 100%;
  margin: 3rem 0 0;
  color: ${theme.colors.black};
`;

const CategoryContainer = styled('div')`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 12px 1fr 12px 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
  width: 100%;

  ${theme.below.sm} {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
`;

const PopularCategories = ({ title, children }) => {
  return (
    <MaxWidth>
      <Container>
        <TitleWithLines>
          {title ? title : t('Popular categories')}
        </TitleWithLines>
        <CategoryContainer>{children}</CategoryContainer>
      </Container>
    </MaxWidth>
  );
};

export default PopularCategories;
