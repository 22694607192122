import React from 'react';
import { styled } from 'linaria/react';

import { cx } from 'linaria';
import { theme } from '../../Theme';

const List = styled('ul')`
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  height: inherit;

  &.left {
    left: 5px;
    justify-content: flex-start;
    button {
      padding: 0 8px;
    }
  }
  &.right {
    right: 0;
    justify-content: flex-end;
  }

  button {
    border: none;
  }

  ${theme.below.sm} {
    &.left {
      li {
        padding: 0 2px;

        &:last-of-type {
          padding-right: 8px;
        }
      }
    }
  }
`;

const ListItem = styled('li')`
  list-style: none;
  display: block;
  padding: 0 8px;

  ${theme.below.sm} {
    .cart-button,
    .mobile-store-button {
      padding: 5px 0;
      height: 54px;
    }
  }
`;

const TopNav = ({ children, left, ...rest }) => (
  <List {...rest} className={cx(left ? 'left' : 'right')}>
    {React.Children.map(children, (item) => (
      <ListItem>{item}</ListItem>
    ))}
  </List>
);

export default TopNav;
