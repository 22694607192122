import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/';
import MaxWidth from '../Layout/MaxWidth';

import { theme } from '../Theme';

const HeroContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 540px;
  margin-bottom: 2.5rem;

  ${theme.above.md} {
    height: 516px;
    margin-bottom: 100px;
  }
`;

const FullWidthImage = styled(Image)`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const Overlay = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

const Wrapper = styled(MaxWidth)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1100px;
  text-align: center;
  color: ${theme.colors.white};

  h1 {
    font-size: ${theme.fontSizes[6]};
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0.2px;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: ${theme.fontSizes[2]};
    font-weight: ${theme.fontWeights.light};
    line-height: 1.5;
  }

  ${theme.above.md} {
    h1 {
      font-size: ${theme.fontSizes[8]};
    }

    p {
      font-size: ${theme.fontSizes[4]};
    }
  }
`;

const HeroImage = ({ url, text, title }) => (
  <HeroContainer>
    <FullWidthImage src={url} fillAvailableSpace />
    <Overlay />
    <Wrapper>
      <h1>{title}</h1>
      <p>{text}</p>
    </Wrapper>
  </HeroContainer>
);

export default HeroImage;
