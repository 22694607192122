import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import Image from '@jetshop/ui/Image/';
import { TrendLink } from '../ui/Button';
import CustomLink from '../ui/CustomLink';

const ImageWrapper = styled(Image)`
  .customLink {
    text-decoration: none;
    cursor: pointer;

    &:hover {
      a {
        background: #720714;
        text-decoration: none;
      }
    }
  }
`;

const CategoryCardWrapper = styled('div')`
  position: absolut;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  &.v-center {
    align-items: center;
  }
  &.v-top {
    align-items: flex-start;
  }
  &.v-bottom {
    align-items: flex-end;
  }

  &.h-center {
    justify-content: center;
    > div {
      h2 {
        text-align: center;
      }
      a {
        margin-left: 0.5rem;
      }
    }
  }
  &.h-left {
    justify-content: flex-start;
    > div {
      h2 {
        text-align: left;
      }
      a {
        margin-right: 0.5rem;
      }
    }
  }
  &.h-right {
    justify-content: flex-end;
    > div {
      h2 {
        text-align: right;
      }
      a {
        margin-left: 0.5rem;
      }
    }
  }
`;

const ContentWrapper = styled('div')`
  height: fit-content;
  max-width: 100%;
  display: flex;
  padding: 2rem;
  flex-direction: column;

  h2 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: ${(props) => props.titlecolor};
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
    text-transform: uppercase;
  }
`;

const ButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 0rem;
  a {
    height: 24px !important;
    margin-top: 0.5rem;
    padding: 0 1.3rem;
    width: fit-content;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: 12px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  }

  &.h-center {
    justify-content: center;
  }
  &.h-left {
    justify-content: flex-start;
  }
  &.h-right {
    justify-content: flex-end;
  }
`;

const CategoryCardButton = styled(TrendLink)`
  background: ${(props) => props.backgroundcolor};
  color: ${(props) => props.textcolor};
  &:hover {
    color: ${(props) => props.backgroundcolor};
    background: ${(props) => props.textcolor};
  }
`;

const ExternalLink = styled('a')`
  text-decoration: none;
  background: ${(props) => props.backgroundcolor};
  color: ${(props) => props.textcolor};
  &:hover {
    color: ${(props) => props.backgroundcolor};
    background: ${(props) => props.textcolor};
  }
`;

const CategoryCard = ({
  imageSrc,
  title = null,
  titleColor = 'white',
  horizontal = null,
  vertical = null,
  buttons = null,
}) => {
  const buttonsArray = (buttons ? JSON.parse(buttons) : { buttons: [] })
    .buttons;

  const CategoryCardContent = () => {
    return (
      <CategoryCardWrapper className={cx('h-' + horizontal, 'v-' + vertical)}>
        <ContentWrapper titlecolor={titleColor}>
          {title && <h2>{title}</h2>}

          <ButtonsWrapper className={'h-' + horizontal}>
            {buttonsArray.map((button, index) => {
              const isExternal = button?.url?.includes('http');
              return (
                <>
                  {isExternal ? (
                    <ExternalLink
                      target={'_blank'}
                      textcolor={button?.textColor ?? '#9b2e2e'}
                      backgroundcolor={button?.backgroundColor ?? 'white'}
                      href={button.url}
                      key={index + button?.url}
                    >
                      {button?.title}
                    </ExternalLink>
                  ) : (
                    <CategoryCardButton
                      textcolor={button?.textColor ?? '#9b2e2e'}
                      backgroundcolor={button?.backgroundColor ?? 'white'}
                      to={button?.url}
                      key={index + button.url}
                    >
                      {button?.title}
                    </CategoryCardButton>
                  )}
                </>
              );
            })}
          </ButtonsWrapper>
        </ContentWrapper>
      </CategoryCardWrapper>
    );
  };

  return (
    <ImageWrapper src={imageSrc} fillAvailableSpace={true}>
      {buttonsArray.length === 1 ? (
        <CustomLink link={buttonsArray[0]?.url}>
          <CategoryCardContent />
        </CustomLink>
      ) : (
        <CategoryCardContent />
      )}
    </ImageWrapper>
  );
};

export default CategoryCard;
