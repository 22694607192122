import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import { styled } from 'linaria/react';
import SubMenuWrapper from './SubMenuWrapper';
import MaxWidth from '../../MaxWidth';

import { theme } from '../../../Theme';

const Wrapper = styled('div')`
  position: relative;
  border-top: 1px solid ${theme.colors.notWhite};
  border-bottom: 1px solid ${theme.colors.notWhite};

  ul {
    margin: 0 -10px;
    padding: 0;
    height: 50px;
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;

    ${theme.between['md-lg']} {
      flex-wrap: wrap;
      min-height: 50px;
      height: auto;
      justify-content: center;
    }
  }

  ul:hover li a {
    color: #b6b6b6;
  }

  ul li:hover a {
    color: #111;
  }
`;

const MainMenuItem = styled('li')`
  padding: 10px;

  a {
    min-height: 1.5rem;
    display: inline-block;
    color: #111;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.6px;
    line-height: 1.6;

    &:hover {
      text-decoration: none;
    }

    &.active {
      color: #000;
      font-weight: bold;
    }
  }
`;

const MenuContainer = ({ queryData, setMenuOverlay }) => (
  <UIMenuContainer>
    {(props) => (
      // When mousing outside the menu, close it
      <Wrapper
        onMouseLeave={() => {
          props.clearActiveCategories();
          setMenuOverlay(false);
        }}
      >
        <MaxWidth>
          <ul>
            {queryData && queryData.categories
              ? queryData.categories.map((cat) => (
                  <MainMenuItem
                    key={cat.id}
                    // When mousing over a menu item, set it as the active nav
                    onMouseEnter={() => {
                      props.setActiveCategory(cat);
                      setMenuOverlay(true);
                    }}
                  >
                    <CategoryLink
                      // When following a category link, close the menu
                      onClick={() => {
                        props.clearActiveCategories();
                        setMenuOverlay(false);
                      }}
                      category={cat}
                    >
                      {cat.name}
                    </CategoryLink>
                  </MainMenuItem>
                ))
              : null}
          </ul>
        </MaxWidth>

        <SubMenuWrapper
          pose={
            props.activeCategories.length > 0 &&
            props.activeCategories[0].subcategories.length > 0
              ? 'open'
              : 'closed'
          }
          activeCategory={props.activeCategories[0]}
          closeNav={() => {
            props.clearActiveCategories();
            setMenuOverlay(false);
          }}
        />
      </Wrapper>
    )}
  </UIMenuContainer>
);

export default MenuContainer;
