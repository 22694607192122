import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { ReactComponent as FacebookIcon } from '../../../svg/Facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../svg/Instagram.svg';

import { theme } from '../../Theme';

const Wrapper = styled('section')`
  margin: 4rem 0;
  margin-top: 1rem;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    list-style: none;
    padding-left: 0;

    li + li {
      margin-top: 1rem;
    }
  }

  h3 {
    color: ${theme.colors.black};
    font-size: 14px};
    font-weight: 700;
    letter-spacing: 0.2px;
    line-height: 2;
    margin-bottom: 1.2rem;
  }

  a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
    color: #333333;
    font-size: ${theme.fontSizes[0]};
    letter-spacing: 0.2px;

    label {
      cursor: pointer;
    }
  }

  ${theme.above.md} {
    margin: 0;

    ul {
      margin: 0;
      flex-direction: row;
      justify-content: flex-end;

      li + li {
        margin-top: 0;
        margin-left: 4rem;
      }
    }

    a {
      color: #828282;

      &:hover {
        color: #333333;

        & svg {
          path {
            stroke: #333333;
          }
        }
      }
    }
  }
`;

const LogoWrapper = styled('span')`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 21px;
    margin-right: 0.5rem;

    path {
      stroke: #333333;
    }
  }

  ${theme.above.md} {
    svg {
      path {
        stroke: #828282;
      }
    }
  }
`;

const SocialLinks = ({ label }) => (
  <Wrapper>
    {label && <h3>{t('Social')}</h3>}
    <ul>
      <li>
        <a href="https://sv-se.facebook.com/mobelmastarna/">
          <LogoWrapper>
            <FacebookIcon />
          </LogoWrapper>
          <label>Facebook</label>
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/mobelmastarna/">
          <LogoWrapper>
            <InstagramIcon />
          </LogoWrapper>
          <label>Instagram</label>
        </a>
      </li>
    </ul>
  </Wrapper>
);

export default SocialLinks;
