import React from 'react';
import { ReactComponent as InputErrorSVG } from '../../svg/InputError.svg';
import { ReactComponent as InputPositiveSVG } from '../../svg/InputPositive.svg';

import { styled } from 'linaria/react';

const StatusRelativeWrapper = styled('div')`
  position: relative;
`;

const StatusWrapper = styled('div')`
  position: absolute;
  right: 0px;
  margin-right: 10px;
  top: -37px;
`;

export const InputError = () => (
  <StatusRelativeWrapper>
    <StatusWrapper>
      <InputErrorSVG />
    </StatusWrapper>
  </StatusRelativeWrapper>
);

export const InputPositive = () => (
  <StatusRelativeWrapper>
    <StatusWrapper>
      <InputPositiveSVG />
    </StatusWrapper>
  </StatusRelativeWrapper>
);
