import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/Image';
import { TrendLink } from '../ui/Button';
import { Price } from '@jetshop/ui/Price';
import { useQuery } from 'react-apollo';
import CampaignProductQuery from './CampaignProductQuery.gql';
import CustomBadges from '../ui/CustomBadges';
import CustomLink from '../ui/CustomLink';

import { theme } from '../Theme';

const CampaignWrapper = styled('div')`
  height: 29vw;
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (min-width: 1300px) {
    height: 370px;
  }

  > * {
    width: 50%;
  }

  &.left {
    flex-direction: row;
    ${theme.below.md} {
      flex-direction: column;
      > * {
        width: 100%;
      }
    }
  }
  &.right {
    flex-direction: row-reverse;
    ${theme.below.md} {
      flex-direction: column;
      > * {
        width: 100%;
      }
    }
  }

  ${theme.below.md} {
    height: auto;
  }

  margin-bottom: 10px;
`;

const Block = styled('div')`
  max-height: 100%;
  background: #e8e4e1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  ${theme.below.lg} {
    padding: 1rem 2.5rem;
  }
  height: 60vw;
`;

const BlockContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  ${theme.below.md} {
    justify-content: space-evenly;
  }
`;

const ProductName = styled('h2')`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 1rem;
  ${theme.below.lg} {
    font-size: 20px;
    line-height: 24px;
    margin-top: 3rem;
  }

  @media (max-width: 870px) {
    margin-top: 1.5rem;
  }
  ${theme.below.md} {
    font-size: 20px;
    line-height: 24px;
    margin-top: 1.5rem;
  }
`;

const BottomContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PriceWrapper = styled(Price)`
  .new-price {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: ${(props) => props.campaignColor};

    ${theme.below.lg} {
      font-size: 35px;
      line-height: 38px;
      margin-bottom: 0.5rem;
    }
  }
  .old-price {
    text-decoration: none;
    font-family: Lato;
    font-style: normal;
    font-weight: 200;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #000000;
    text-transform: lowercase;
  }

  .old-price::before {
    content: 'Ord pris ';
    text-transform: uppercase;
  }
`;

const ImageBlock = styled('div')`
  ${theme.below.md} {
    height: 60vw;
  }
`;

const ButtonWrapper = styled(TrendLink)`
  height: 30px !important;
  margin: 1rem 0 1.5rem 0;
  padding: 0 2.5rem;
  min-width: 150px;
  width: fit-content;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border-radius: 15px;
  color: ${(props) => props.color};
  background: ${(props) => props.backgroundcolor};

  display: block;
  line-height: 30px;
  text-align: center;
`;

// const CampaignBadges = styled('div')`
//   display: block;
//   width: 100%;
//   height: 100%;
// `;

// const BadgeWrapper = styled('div')`
//   height: 28px;
//   width: fit-content;
//   padding: 0 1rem;
//   background: ${(props) => props.campaignColor};
//   color: white;
//   line-height: 28px;
//   text-transform: uppercase;
//   font-size: 12px;
//   font-family: Lato;
//   font-weight: 800;
//   letter-spacing: 0.1em;
//   position: absolute;

//   &.top {
//     top: 10px;
//   }
//   &.bottom {
//     bottom: 10px;
//   }
//   &.left {
//     left: 10px;
//   }
//   &.right {
//     right: 10px;
//   }
// `;

const CampaignRowWrapper = styled('div')`
  div {
    text-decoration: none;
    h2 {
      color: black;
    }
  }

  .customLink {
    text-decoration: none;
    cursor: pointer;

    &:hover {
      a {
        background: #720714;
        text-decoration: none;
      }
    }
  }
`;

const CampaignRow = ({
  imageSrc = null,
  imageBlockAlignment = 'left',
  campaignColor = '#EF843C',
  buttonText,
  buttonTextColor = 'white',
  buttonBackgroundColor = '#9b2e2e',
  articleNumber = null,
}) => {
  const { data, loading, error } = useQuery(CampaignProductQuery, {
    variables: {
      articleNumber,
    },
  });
  if (error) return null;
  if (loading) return null;

  let product = data.product;
  let image = imageSrc ?? product?.images[1]?.url ?? product?.images[0].url;

  // const alignment = badge => {
  //   switch (badge.location) {
  //     case 'TOP_LEFT':
  //       return imageBlockAlignment === 'left' ? 'top left' : ' top right';
  //     case 'TOP_RIGHT':
  //       return imageBlockAlignment === 'left' ? 'top right' : ' top left';
  //     case 'BOTTOM_LEFT':
  //       return imageBlockAlignment === 'left' ? 'bottom left' : 'bottom right';
  //     case 'BOTTOM_RIGHT':
  //       return imageBlockAlignment === 'left' ? 'top right' : ' top left';
  //     default:
  //       break;
  //   }
  //   return 'top left';
  // };

  const CampaignRowContent = () => {
    return (
      <CampaignWrapper className={imageBlockAlignment}>
        <ImageBlock>
          <Image src={image} fillAvailableSpace={true}>
            <CustomBadges
              badges={product.badges}
              horizontalInverted={imageBlockAlignment === 'right'}
            />
            {/* <CampaignBadges>
    {product.badges.map((badge, index) => (
      <BadgeWrapper
        key={index + '-' + product.articleNumber}
        campaignColor={campaignColor}
        className={alignment(badge)}
      >
        <p>{badge.text.length > 0 ? badge.text : badge.name}</p>
      </BadgeWrapper>
    ))}
  </CampaignBadges> */}
          </Image>
        </ImageBlock>
        <Block>
          <BlockContent>
            <ProductName>{product?.name}</ProductName>
            <BottomContent>
              <PriceWrapper
                previousPrice={product?.previousPrice}
                price={product.price}
                campaignColor={campaignColor}
              />
              <ButtonWrapper
                color={buttonTextColor}
                backgroundcolor={buttonBackgroundColor}
                to={product?.primaryRoute?.path}
              >
                {buttonText ?? 'Köp'}
              </ButtonWrapper>
            </BottomContent>
          </BlockContent>
        </Block>
      </CampaignWrapper>
    );
  };
  return (
    <CampaignRowWrapper>
      {product?.primaryRoute?.path ? (
        <CustomLink link={product?.primaryRoute?.path}>
          <CampaignRowContent />
        </CustomLink>
      ) : (
        <CampaignRowContent />
      )}
    </CampaignRowWrapper>
  );
};

export default CampaignRow;
