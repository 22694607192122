import React from 'react';
import { styled } from 'linaria/react';
import { useQuery } from 'react-apollo';
import bestsellersQuery from './Bestsellers.gql';
import MaxWidth from '../Layout/MaxWidth';
import StyledProductGrid from '../CategoryPage/ProductGrid';

import { theme } from '../Theme';

const SelectedProductsWrapper = styled(MaxWidth)`
  margin-bottom: 3rem;
  a {
    width: calc(100% / 4);
    ${theme.below.lg} {
      width: calc(100% / 2);
    }
  }
`;
const Title = styled('h1')`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const SelectedProducts = ({ title, articleNumbers }) => {
  const artNr = articleNumbers
    .split(',')
    .map((articleNumber) => articleNumber.trim());

  const { data, loading, error } = useQuery(bestsellersQuery, {
    variables: {
      articleNumbers: artNr,
    },
    errorPolicy: 'ignore',
  });

  if (loading || error) {
    return null;
  }

  return (
    <SelectedProductsWrapper>
      {title && <Title>{title}</Title>}
      <StyledProductGrid
        loading={loading}
        products={data?.products}
        listName={title}
        categoryPath={null}
      />
    </SelectedProductsWrapper>
  );
};

export default SelectedProducts;
