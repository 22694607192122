import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/';
import { TrendLink } from '../ui/Button';

import { theme } from '../Theme';

const Container = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 6rem 0 8rem;
  color: ${theme.colors.black};

  ${theme.below.sm} {
    margin: 4rem 0;
  }
`;

const Title = styled('h2')`
  font-family: ${theme.fonts.primary}, serif;
  font-style: normal;
  font-weight: 700;
  font-size: ${theme.fontSizes[8]};
  margin-bottom: 42px;
  text-transform: uppercase;
  text-align: center;
  line-height: 129%;

  padding: 0 40px;

  ${theme.below.sm} {
    font-size: ${theme.fontSizes[6]};
  }
`;

const Text = styled('p')`
  font-family: ${theme.fonts.primary}, serif;
  font-style: normal;
  font-size: 22px;
  font-weight: 300;

  ${theme.below.sm} {
    font-size: 18px;
  }

  margin: 0 1rem;
  line-height: 150%;
  letter-spacing: 0.2px;

  max-width: 958px;
  text-align: center;
`;

const Button = styled(TrendLink)`
  margin: 4em 0 0;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`;

const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.white};
`;

const Quote = ({ text, title, buttonUrl, buttonText, children, imageUrl }) => {
  if (imageUrl) {
    return (
      <Container>
        <BackgroundImage src={imageUrl} fillAvailableSpace>
          {title && <Title white>{title}</Title>}
          <Text>{text || children}</Text>
          {buttonUrl && <Button to={buttonUrl || '/'}>{buttonText}</Button>}
        </BackgroundImage>
      </Container>
    );
  }
  return (
    <Container>
      {title && <Title>{title}</Title>}
      <Text>{text || children}</Text>
      {buttonUrl && <Button to={buttonUrl || '/'}>{buttonText}</Button>}
    </Container>
  );
};

export default Quote;
