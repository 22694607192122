import React from 'react';
import { styled } from 'linaria/react';

import { theme } from '../Theme';

const CircleBadgeWrap = styled('div')`
  position: relative;
  width: 100%;
  padding-bottom: 100%;

  border-radius: 50%;
  background: ${(p) => p.circleColor};

  > div {
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    bottom: 12px;

    border: 1px solid #fff;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    ${theme.below.md} {
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px;
    }

    > span {
      font-weight: 700;
      font-size: 24px;

      ${theme.below.md} {
        font-size: 16px;
      }
    }
  }
`;

const CircleBadge = ({ color, text }) => (
  <CircleBadgeWrap circleColor={color}>
    <div>
      <span>{text}</span>
    </div>
  </CircleBadgeWrap>
);
export default CircleBadge;
