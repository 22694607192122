import { styled } from 'linaria/react';
import { Price } from '@jetshop/ui/Price';

import { theme } from '../Theme';

const Container = styled('div')`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const Heading = styled('h1')`
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0.2px;
  line-height: 1;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0.5rem;
  max-width: 95%;

  ${theme.above.md} {
    font-size: 36px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
`;

const SubHeading = styled('h2')`
  font-weight: ${theme.fontWeights.light};
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 1.6;
  margin-bottom: 0.5rem;

  ${theme.above.md} {
    font-size: 22px;
    margin-bottom: 1rem;
  }
`;

const ArticleNumber = styled('p')`
  line-height: 23px;
  font-size: 13px;
  color: #969696;
  margin-bottom: 20px;
`;

const ShortDescription = styled('article')`
  display: block;
  font-weight: ${theme.fontWeights.regular};
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 1.5;
  margin-bottom: 1em;

  ${theme.above.md} {
    font-size: 14px;
    margin-top: 1em;
  }
`;

const ProductContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${theme.colors.white};
`;

const ProductDetailsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  .lipscore-rating {
    margin-bottom: 20px;
  }

  ${Price.Wrapper} {
    font-weight: 700;
    font-size: 28px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: #111;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    ${theme.above.md} {
      font-size: 30px;
    }
  }

  ${Price.New} {
    color: ${theme.colors.red};
    margin-right: 1rem;
  }

  ${Price.Old} {
    text-decoration: line-through;
  }
`;

export {
  Container,
  Heading,
  SubHeading,
  ArticleNumber,
  ShortDescription,
  ProductContainer,
  ProductDetailsWrapper,
};
