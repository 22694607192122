import React, { useState } from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/';
import ProductLink from '@jetshop/ui/ProductLink';
import { Price } from '@jetshop/ui/Price';
import { ProductDetailsWrapper } from '../ProductPage/styledComponents';
import ColorBox from '../ui/ColorBox';
import { MastarPrisComponent } from '../ui/MastarPrisComponent';

import { theme } from '../Theme';

export const ProductWrapper = styled('div')`
  width: 100%;
  height: 100%;
  ${theme.below.lg} {
    width: 50%;
    height: fit-content;
  }
  ${theme.below.sm} {
    width: 100%;
    height: fit-content;
  }
`;

const ProductBlockLink = styled(ProductLink)`
  text-decoration: none;
  color: inherit;
  border: 1px solid #e8e8e8;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  background: rgba(0, 0, 0, 0.04);

  &:nth-child(1) {
    border: none;
    border-bottom: 1px solid white;
    ${theme.below.lg} {
      border: none;
      border-right: 1px solid white;
    }
    ${theme.below.sm} {
      border: none;
      border-bottom: 1px solid white;
    }
  }
  &:nth-child(2) {
    border: none;
    border-top: 1px solid white;
    ${theme.below.lg} {
      border: none;
      border-left: 1px solid white;
    }
    ${theme.below.sm} {
      border: none;
      border-top: 1px solid white;
    }
  }
`;

const ImageWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  > div {
    mix-blend-mode: multiply;
  }

  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  [data-flight-image-container] {
  }
`;

const ProductDetails = styled(ProductDetailsWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 1.5rem 0.2rem 1.5rem;
  align-items: flex-start;
  min-height: 63px;

  ${Price.Wrapper} {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    color: ${theme.colors.black};
  }

  ${Price.Old} {
    margin-right: 0.5rem;
    align-items: center;
    opacity: 0.3;
  }

  ${Price.New} {
    margin-right: 0.5rem;
    align-items: center;
  }
`;

const Name = styled('p')`
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  line-height: 16px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.3rem 0;
`;

const InfoWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
  }

  .tooltip {
    div {
      left: unset;
      right: 0;
      transform-origin: right bottom;
    }
  }
`;

const ColorBoxesWrapper = styled('div')`
  margin: 0.5rem 0;
  display: flex;
  min-height: 15px;
  justify-content: flex-start;
`;

export const HeroProductCard = ({ product, categoryPath = null }) => {
  const [image] = useState(product?.images?.[0]?.url);

  if (!product) return null;

  const colorVariants = product.variants?.options?.find((option) =>
    option.name.toLowerCase().includes('färg')
  )?.values;

  const mastarPris = product?.customFields?.find(
    ({ key }) => key === 'Mastarpris'
  )?.boolValue;

  return (
    <ProductWrapper data-testid="product">
      <ProductBlockLink product={product}>
        <ImageWrapper>
          <Image alt={image} src={image} />
        </ImageWrapper>
        <ProductDetails>
          <ColorBoxesWrapper>
            {colorVariants?.length > 0 && (
              <>
                {colorVariants.map((color, index) => (
                  <ColorBox group={color} key={color + index} />
                ))}
              </>
            )}
          </ColorBoxesWrapper>
          <InfoWrapper>
            <Name>{product.name}</Name>
            {mastarPris ? (
              <MastarPrisComponent product={product} productcard={true} />
            ) : (
              <Price
                previousPrice={product?.previousPrice}
                price={product?.price}
              />
            )}
          </InfoWrapper>
        </ProductDetails>
      </ProductBlockLink>
    </ProductWrapper>
  );
};

export default HeroProductCard;
