import React from 'react';
import { styled } from 'linaria/react';
import { Above } from '@jetshop/ui/Breakpoints';
import MaxWidth from '../MaxWidth';
import FooterLinks from './FooterLinks';
import SocialLinks from './SocialLinks';
import Logo from '../../ui/Logo';

import { theme } from '../../Theme';

const Wrapper = styled('footer')`
  background: ${theme.colors.notWhite};
  padding: 3rem 1rem 1rem;
`;

const Spacer = styled('div')`
  width: 100%;
  height: 1px;
  border-top: 1px solid #e8e8e8;
  margin: 1rem 0;
`;

const FlexContainer = styled('div')`
  padding-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.alignTop ? `flex-start` : `center`)};
  justify-content: ${(props) =>
    props.center ? `center` : props.spaced ? `space-between` : `flex-start`};
`;

const PoweredBy = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 14px;
    color: #828282;
    letter-spacing: 0.2px;
    line-height: 1.6;
    margin: 0;

    a {
      font-size: 14px;
      color: #828282;
      letter-spacing: 0.2px;
      line-height: 1.6;
      text-decoration: none;
    }
  }

  p + p {
    margin-left: 2rem;
  }

  ${theme.above.md} {
    width: 50%;
    justify-content: flex-start;
  }
`;

const Footer = ({ pageLinks }) => (
  <Wrapper className='hideInPrint'>
    <MaxWidth>
      <Above breakpoint="md">
        {(matches) =>
          matches ? (
            <>
              <FlexContainer spaced alignTop>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Logo size={132} />
                </div>
                <FooterLinks pageLinks={pageLinks} />
              </FlexContainer>

              <Spacer />

              <FlexContainer spaced>
                <PoweredBy>
                  <p>Möbelmästarna</p>
                </PoweredBy>
                <SocialLinks />
              </FlexContainer>
            </>
          ) : (
            <>
              <Logo size={117} />
              <FooterLinks pageLinks={pageLinks} />
              <SocialLinks label />
              <Spacer />
              <PoweredBy>
                <p>Möbelmästarna</p>
              </PoweredBy>
            </>
          )
        }
      </Above>
    </MaxWidth>
  </Wrapper>
);

export default Footer;
