import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';

import { theme } from '../Theme';

const CategoryTripletsWrapper = styled(MaxWidth)`
  ${theme.below.md} {
    padding: 0;
  }
`;

const CategoriesWrapper = styled('div')`
  width: 100%;
  height: 70vw;
  max-height: 850px;
  background: white;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 0 auto;

  &.left {
    .div0 {
      grid-area: 1 / 1 / 5 / 3;
    }
    .div1 {
      grid-area: 1 / 3 / 3 / 5;
    }
    .div2 {
      grid-area: 3 / 3 / 5 / 5;
    }
  }

  &.right {
    .div0 {
      grid-area: 1 / 1 / 3 / 3;
    }
    .div1 {
      grid-area: 3 / 1 / 5 / 3;
    }
    .div2 {
      grid-area: 1 / 3 / 5 / 5;
    }
  }
  ${theme.below.md} {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    height: 285vw;
    max-height: 285vw;

    &.left,
    &.right {
      .div0 {
        grid-area: 1 / 1 / 3 / 2;
      }
      .div1 {
        grid-area: 3 / 1 / 4 / 2;
      }
      .div2 {
        grid-area: 4 / 1 / 5 / 2;
      }
    }
  }
`;

const CategoryCardWrapper = styled('div')``;

const Title = styled('h1')`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const CategoryTriplets = ({
  children,
  fullHeightContentAt = null,
  title = null,
}) => {
  let align = fullHeightContentAt ?? 'left';
  return (
    <CategoryTripletsWrapper>
      {title && <Title>{title}</Title>}
      <CategoriesWrapper className={align}>
        {children &&
          children.map((child, index) => {
            if (index > 2) {
              return null;
            }
            return (
              <CategoryCardWrapper key={index} className={'div' + index}>
                {child}
              </CategoryCardWrapper>
            );
          })}
      </CategoriesWrapper>
    </CategoryTripletsWrapper>
  );
};

export default CategoryTriplets;
