import React, { useEffect, useState, useMemo } from 'react';
import fetch from 'node-fetch';
import { useQuery } from '@apollo/react-hooks';
import storeDataQuery from './StoreData.gql';
import { useLocation } from 'react-router-dom';

const StoreContext = React.createContext();

function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function haversineDistance(coords1, coords2, isMiles) {
  function toRad(x) {
    return (x * Math.PI) / 180;
  }

  var lon1 = coords1[0];
  var lat1 = coords1[1];

  var lon2 = coords2[0];
  var lat2 = coords2[1];

  var R = 6371; // km

  var x1 = lat2 - lat1;
  var dLat = toRad(x1);
  var x2 = lon2 - lon1;
  var dLon = toRad(x2);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;

  if (isMiles) d /= 1.60934;

  return d;
}

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };
  return [storedValue, setValue];
}

const StoreProvider = ({ children }) => {
  const { data, loading } = useQuery(storeDataQuery);
  const search = useQueryParams();

  const [savedStore, saveStore] = useLocalStorage('selected_store');
  const apiKey = 'Yy6SzcWDqyk4S49I9o_hNDuyI9iNy8Og';
  const baseUrl = 'https://inviiv.io';
  const [storeList, setList] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const storeSlugs = useMemo(() => {
    return storeList?.map((store) => store?.store_slug);
  }, [storeList]);

  useEffect(() => {
    if (search.get('store')) {
      const store = storeList.find(
        (store) => store.store_slug === search.get('store')
      );
      if (store) {
        updateStore(store);
      }
    }
  }, [search, storeList]);

  useEffect(() => {
    fetch(`${baseUrl}/api/stores/stores?key=${apiKey}`, { cache: 'no-store' })
      .then((res) => res?.json())
      .then((data) =>
        setList(
          data
            .filter((store) => {
              return store?.prefix !== 'HK';
            })
            .sort((a, b) => (a?.name > b?.name ? 1 : -1))
        )
      );
  }, []);

  useEffect(() => {
    if (storeList && data?.stores) {
      setList(
        storeList.map((store) => {
          const storeInfo = data.stores.find((s) => {
            if (store.name === 'Göteborg') {
              return s.id === 3;
            }
            if (store.name === 'Ed') {
              return s.id === 53;
            }
            return s.name.toLowerCase().includes(store.name.toLowerCase());
          });

          return {
            ...store,
            extra: storeInfo,
          };
        })
      );
    }
  }, [data, loading, storeList.length]);

  useEffect(() => {
    if (savedStore && !selectedStore) {
      updateStore(savedStore);
    } else if (
      !savedStore &&
      !selectedStore &&
      'geolocation' in navigator &&
      storeList
    ) {
      navigator.geolocation.getCurrentPosition((position) => {
        setClosestStore(position.coords.latitude, position.coords.longitude);
      });
    }
  }, [savedStore, selectedStore, storeList]);

  const setClosestStore = (lat, lng) => {
    const listWithDistance = storeList
      .map((store) => {
        return {
          ...store,
          distance: haversineDistance(
            [
              store?.extra?.coordinates.longitude,
              store?.extra?.coordinates.latitude,
            ],
            [lng, lat]
          ),
        };
      })
      .filter((store) => !isNaN(store.distance))
      .sort((a, b) => (a.distance > b.distance ? 1 : -1));

    if (listWithDistance.length > 0) {
      updateStore(listWithDistance[0]);
    }
  };

  const updateStore = (store) => {
    setSelectedStore(store);
    saveStore(store);
  };

  const value = {
    apiKey,
    baseUrl,
    storeList,
    selectedStore,
    updateStore,
    storeSlugs,
  };

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

export { StoreContext, StoreProvider };
