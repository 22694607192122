import React, { useState } from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import AddToCartButton from '../ProductPage/AddToCart/AddToCartButton';
import AddToCartWrapper from '../ProductPage/AddToCart/AddToCartWrapper';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import { VariantSelector } from '../ProductPage/VariantSelector';
import { PriceComponent } from '../ProductPage/PriceComponent';
import { theme } from '../Theme';

const CareProductWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .image-wrapper {
    width: 50%;
  }
  .info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    padding: 40px 0 40px 40px;
  }
  p {
    line-height: 1.5 !important;
  }
  h4 {
    margin: 0;
  }

  .quantity-input-wrapper {
    display: none;
  }

  ${theme.below.lg} {
    flex-direction: column;
    .image-wrapper {
      width: 100%;
    }
    .info-wrapper {
      width: 100%;
      padding: 0;
      padding-bottom: 20px;
    }
  }
`;

export const CareProduct = ({ product }) => {
  const variantHandler = useProductVariants(
    product?.value?.articleNumber ? product?.value : undefined
  );
  const { selectedVariant: selectedVariation } = variantHandler;
  const stockStatus = useStockStatus(selectedVariation || product?.value);
  const imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4];
  const short = product?.value?.shortDescription?.substring(0, 100);
  const long = product?.value?.shortDescription;
  const [showDescription, setShowDescription] = useState(short);

  return (
    <CareProductWrapper>
      <div className="image-wrapper">
        <Image
          sizes={imageSizes}
          aspect={'1:1'}
          alt={product?.value?.images[0]?.alt}
          src={product?.value?.images[0]?.url}
        />
      </div>
      <div className="info-wrapper">
        <h4 product={product?.value}>{product?.value?.name}</h4>
        <div className="description">
          <p
            onClick={() =>
              setShowDescription(showDescription === short ? long : short)
            }
            dangerouslySetInnerHTML={{
              __html:
                showDescription +
                (showDescription === short
                  ? '...<span>Läs mer</span>'
                  : '<span>Visa mindre</span>'),
            }}
          />
        </div>
        {product?.value?.hasVariants && (
          <VariantSelector
            product={product?.value}
            selectedVariation={selectedVariation}
            variantHandler={variantHandler}
          />
        )}
        <PriceComponent product={product?.value} />
        <AddToCartWrapper
          product={product?.value}
          variantHandler={variantHandler}
        >
          {({ errors }) => (
            <AddToCartButton
              inStock={stockStatus?.status === 'inStock'}
              variantHandler={variantHandler}
              errors={errors}
            />
          )}
        </AddToCartWrapper>
      </div>
    </CareProductWrapper>
  );
};
