import React from 'react';
import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import Button from '../../ui/Button';
import InputWithLabel from '../../Forms/Input';
import { Form } from 'formik';

import { theme } from '../../Theme';

const StyledButton = styled(Button)`
  height: 50px;
  text-transform: uppercase;
  width: 100%;
  font-size: 14px;
  border-radius: 50px;

  ${theme.above.md} {
    height: 50px;
    max-width: none;
  }
`;

const QuantityButtonWrapper = styled(Form)`
  display: flex;
  width: 100%;
  align-items: flex-end;
  margin: 1.5rem 0 0.75rem;
  position: relative;
  input {
    position: absolute;
    top: 4px;
    left: 4px;
    height: 42px;
    width: 42px;
    border-radius: 50% !important;
    border: 1px solid #e0e0e0;
    font-family: ${theme.fonts.primary};
    color: #111;
    font-size: 16px;
    box-shadow: ${(props) => props.hasquantityerror};
    :focus {
      outline: none;
    }
  }

  label {
    display: none;
  }

  ${theme.below.md} {
    margin-bottom: 0.5rem;
  }
`;

const ErrorContainer = styled('div')`
  position: absolute;
  font-size: 12px;
  bottom: 74%;
  left: 20%;
  margin-bottom: 4px;
  color: ${theme.colors.red};
`;

const AddToCartButton = ({
  inStock,
  variantHandler,
  distributionPackageSize,
  errors,
}) => {
  const { getMissingOptions } = variantHandler;

  const missingOptions = getMissingOptions();
  const hasMissingOption = missingOptions?.length > 0;

  const isQuantityError = false;

  const errorKeys = Object.keys(errors);
  const hasQuantityError = errorKeys.length > 0;

  return (
    <QuantityButtonWrapper
      hasquantityerror={
        hasQuantityError ? '0 2px 8px rgba(0, 0, 0, 0.25)' : 'none'
      }
    >
      {errorKeys.length > 0 && (
        <ErrorContainer>
          {errorKeys.map((key, index) => (
            <p key={index}>{errors[key]}</p>
          ))}
        </ErrorContainer>
      )}
      <InputWithLabel
        disableValidation
        wrapperClassName="quantity-input-wrapper"
        min={distributionPackageSize}
        step={distributionPackageSize}
        type="number"
        name="quantity"
        label="Quantity"
      />
      <StyledButton
        disabled={hasMissingOption || !inStock || isQuantityError}
        style={
          hasMissingOption || !inStock || isQuantityError
            ? { opacity: 0.5 }
            : null
        }
        type="submit"
        data-testid="add-to-cart"
      >
        {missingOptions?.[0]
          ? t('Please select {option}', { option: missingOptions?.[0].name })
          : t('Add to cart')}
      </StyledButton>
    </QuantityButtonWrapper>
  );
};

export default AddToCartButton;
