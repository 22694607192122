import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';

const HeroWrapper = styled('section')`
  display: flex;
  flex-direction: column;
  h1 {
    font-family: ${theme.fonts.primary};
    font-size: 28px;
    line-height: 34px;
    font-weight: 500;
    text-align: center;
    margin: 16px 0;
  }
  h2 {
    font-family: ${theme.fonts.primary};
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
    ${theme.below.lg} {
      font-size: 35px;
      line-height: 35px;
    }
  }
  h3 {
    font-family: ${theme.fonts.primary};
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    text-align: center;
    margin: 0;
  }
`;

const HeroItemsWrapper = styled('div')`
  width: 100%;
  height: 40vw;
  max-height: 800px;
  background: white;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  margin: 0 auto;

  ${theme.below.sm} {
    height: 100%;
    max-height: 285vw;
    display: flex;
    flex-direction: column;

    .product-wrapper {
      [data-flight-image-container] {
        min-height: 48vw;
      }
    }
    .imagebox-wrapper {
      [data-flight-image-container] {
        min-height: 400px;
      }
    }
  }

  .LEFT {
    [data-flight-image-children] {
      justify-content: flex-start;
    }
  }
  .CENTER {
    [data-flight-image-children] {
      justify-content: center;
    }
  }

  .RIGHT {
    [data-flight-image-children] {
      justify-content: flex-end;
    }
  }

  &.layout4 {
    .position1 {
      grid-area: 1 / 1 / 5 / 3;
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
    .position2 {
      grid-area: 1 / 3 / 3 / 5;
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
    .position3 {
      grid-area: 3 / 3 / 5 / 4;
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
    .position4 {
      grid-area: 3 / 4 / 5 / 5;
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
  }

  &.layout3 {
    ${theme.below.lg} {
      height: auto;
      min-height: 70vw;
    }
    .position1 {
      grid-area: 1 / 1 / 5 / 4;
      ${theme.below.lg} {
        grid-area: 1 / 1 / 3 / 5;
      }
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
    .position2 {
      grid-area: 1 / 4 / 3 / 5;
      ${theme.below.lg} {
        grid-area: 3 / 1 / 7 / 3;
        & > div {
          width: 100%;
          [data-flight-image-container] {
            padding-bottom: 85% !important;
            height: 0 !important;
          }
        }
      }
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
    .position3 {
      ${theme.below.lg} {
        grid-area: 3 / 3 / 7 / 5;
        & > div {
          width: 100%;
          [data-flight-image-container] {
            padding-bottom: 85% !important;
            height: 0 !important;
          }
        }
      }
      grid-area: 3 / 4 / 5 / 5;
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
  }

  &.layout2 {
    .position1 {
      grid-area: 1 / 1 / 5 / 3;
      ${theme.below.lg} {
        grid-area: 1 / 1 / 3 / 2;
      }
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
    .position2 {
      grid-area: 1 / 3 / 5 / 5;
      ${theme.below.lg} {
        grid-area: 3 / 1 / 4 / 2;
      }
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
  }

  &.layout1 {
    .position1 {
      grid-area: 1 / 1 / 5 / 5;
      ${theme.below.lg} {
        grid-area: 1 / 1 / 3 / 2;
      }
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
  }
`;

export const Hero = ({ title, children, fullWidth }) => {
  return (
    <HeroWrapper>
      {title?.value && <h1>{title?.value}</h1>}
      <HeroItemsWrapper
        className={cx(
          'layout' + children?.length,
          fullWidth?.value && 'fullwidth'
        )}
      >
        {children}
      </HeroItemsWrapper>
    </HeroWrapper>
  );
};
