import React from 'react';
import AddToCartFormik from '@jetshop/core/components/Mutation/AddToCartFormik';
import CartQuery from '../../Cart/CartQuery.gql';
import addToCart from '../../Cart/addToCart.gql';
import { useNotification } from '@jetshop/core/components/Notifications';
import t from '@jetshop/intl';
import ProductToastWrapper from './ProductToast';

function useValidation({ distributionPackageSize = 1 }) {
  function triggerValidation({ quantity }) {
    const errors = {};

    if (distributionPackageSize > 1 && quantity % distributionPackageSize !== 0)
      errors.quantity = t(
        'The selected quantity has to be a multiple of {distributionPackageSize}.',
        {
          distributionPackageSize,
        }
      );

    return errors;
  }
  return { triggerValidation };
}

const AddToCartWrapper = ({ children, product, variantHandler }) => {
  const { distributionPackageSize } = product;

  const { selectedVariant: selectedVariation, getMissingOptions } =
    variantHandler;

  const { triggerValidation } = useValidation({
    distributionPackageSize,
  });

  const [trigger, dismiss] = useNotification();

  const onAddToCartInit = ({ mutationId }) => {
    trigger(
      <ProductToastWrapper
        selectedVariation={selectedVariation}
        product={product}
      />,
      {
        id: mutationId,
        type: 'add-to-cart',
        autoCloseAfter: 2500,
      }
    );
  };
  const onAddToCartError = () => {
    return ({ selectedVariation, mutationId, error }) => {
      dismiss(mutationId);
      trigger(
        <ProductToastWrapper
          selectedVariation={selectedVariation}
          product={product}
          error={error}
        />,
        {
          id: mutationId,
          type: 'add-to-cart',
          autoCloseAfter: 2500,
        }
      );
    };
  };

  return (
    <AddToCartFormik
      cartQuery={CartQuery}
      cartMutation={addToCart}
      onAddToCartInit={onAddToCartInit}
      onAddToCartError={onAddToCartError}
      product={product}
      variant={selectedVariation}
      getMissingOptions={getMissingOptions}
      validate={triggerValidation}
      enableReinitialize
      initialValues={{ quantity: distributionPackageSize }}
    >
      {({ errors }) => children({ errors })}
    </AddToCartFormik>
  );
};

export default AddToCartWrapper;
