import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as PDFIcon } from '../../svg/Pdf.svg';
import { ReactComponent as FileIcon } from '../../svg/File.svg';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { theme } from '../Theme';
import t from '@jetshop/intl';

const DownloadableFilesWrapper = styled('div')`
  h3 {
    font-weight: 400;
  }
  ul {
    padding: 0;
    list-style: none;

    li {
      display: flex;
      cursor: pointer;
      margin-bottom: 2rem;

      svg {
        height: 60px;
      }

      .file-text-column {
        margin-left: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: end;

        p {
          margin: 0;
          line-height: 100% !important;
          letter-spacing: 0.08rem !important;

          ${theme.below.lg} {
            letter-spacing: 0.06rem !important;
          }

          &.title {
            margin: 10px 0;
          }
          &.file-size {
            font-size: 12px !important;
            opacity: 0.5;
          }
          &.click {
            text-transform: uppercase;
            letter-spacing: 0.02rem !important;
          }
        }
      }
    }
  }
`;

export const DownloadableFiles = ({ files }) => {
  return (
    <DownloadableFilesWrapper>
      {/* <h3>{t('Download')}</h3> */}
      <ul>
        {files?.map((file, index) => {
          return <File key={file + index} file={file} />;
        })}
      </ul>
    </DownloadableFilesWrapper>
  );
};

export const File = ({ file }) => {
  const isPdf = file?.endsWith('.pdf');

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };

  const fileUrlArray = file?.split('/');
  // Parse file name and capitalize first letter and replace _
  const fileName = capitalizeFirstLetter(
    fileUrlArray[fileUrlArray?.length - 1]?.replace(/_|-/g, ' ')
  );

  const { selectedChannel } = useContext(ChannelContext);
  const fetchUrl = `${selectedChannel?.url}${
    file?.startsWith('/') ? '' : '/'
  }${file}`;

  const handleDownloadClick = () => {
    fetch(fetchUrl).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();
      });
    });
  };

  return (
    <li
      onClick={() => {
        handleDownloadClick();
      }}
    >
      {isPdf ? <PDFIcon /> : <FileIcon />}
      <div className="file-text-column">
        <p className="title">{fileName}</p>
        <p className="click">{t('Click here')}</p>
      </div>
    </li>
  );
};
