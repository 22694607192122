import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import Image from '@jetshop/ui/Image/';
import { TrendLink } from '../ui/Button';
import CustomLink from '../ui/CustomLink';

const Content = styled('div')`
  background: grey;
  height: 100%;
  overflow: hidden;

  .customLink {
    height: 100%;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      a {
        background: #720714;
        text-decoration: none;
      }
    }
  }
`;

const HeroImage = styled(Image)`
  height: 100%;
  min-height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Overlay = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 1.5rem;
  color: white;
  align-items: center;
  justify-content: center;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
`;

const CampaignText = styled('h2')`
  max-width: 50rem;
  margin-top: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  margin-bottom: 2px;
  color: ${(props) => props.textcolor};
  ${theme.below.sm} {
    font-size: 35px;
  }
`;

const SubText = styled('h2')`
  max-width: 50rem;
  margin-top: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.03em;
  color: ${(props) => props.textcolor};
`;

const HeaderButton = styled(TrendLink)`
  height: 30px !important;
  margin: 2.5rem 0 1rem 0;
  padding: 0 2.5rem;
  width: fit-content;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border-radius: 15px;
  background: ${(props) => props.buttonbackgroundcolor};
  color: ${(props) => props.buttontextcolor};
`;

const CampaignHeader = ({
  imageSrc,
  text = null,
  textColor = 'white',
  subText = null,
  buttonText = null,
  buttonUrl = null,
  buttonTextColor = 'white',
  buttonBackgroundColor = '#9b2e2e',
}) => {
  const HeaderContent = () => {
    return (
      <HeroImage src={imageSrc} fillAvailableSpace>
        <Overlay>
          {text && <CampaignText textcolor={textColor}>{text}</CampaignText>}
          {subText && <SubText textcolor={textColor}>{subText}</SubText>}
          {buttonText && buttonUrl && (
            <HeaderButton
              buttontextcolor={buttonTextColor}
              buttonbackgroundcolor={buttonBackgroundColor}
              to={buttonUrl || '/'}
            >
              {buttonText}
            </HeaderButton>
          )}
        </Overlay>
      </HeroImage>
    );
  };

  return (
    <Content>
      <CustomLink link={buttonUrl}>
        <HeaderContent />
      </CustomLink>
    </Content>
  );
};

export default CampaignHeader;
