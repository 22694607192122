import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';
import MaxWidth from '../Layout/MaxWidth';

const BoxRowWrapper = styled('section')`
  display: flex;
  flex-direction: column;
  h1 {
    font-family: ${theme.fonts.primary};
    font-size: 28px;
    line-height: 34px;
    font-weight: 500;
    text-align: center;
    margin: 16px 0;
  }
  h2 {
    font-family: ${theme.fonts.primary};
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
    text-align: center;
    margin: 0;
  }
  h3 {
    font-family: ${theme.fonts.primary};
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    margin: 0;
  }
`;

const BoxRowItemsWrapper = styled(MaxWidth)`
  &.fullwidth {
    max-width: 100%;
  }
  width: 100%;
  height: 70vw;
  max-height: 850px;
  background: white;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 0 auto;

  ${theme.below.lg} {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    height: 285vw;
    max-height: 285vw;
    padding: 0;
  }

  .LEFT {
    [data-flight-image-children] {
      justify-content: flex-start;
    }
  }
  .CENTER {
    [data-flight-image-children] {
      justify-content: center;
    }
  }

  .RIGHT {
    [data-flight-image-children] {
      justify-content: flex-end;
    }
  }

  &.layout4 {
    .position1 {
      grid-area: 1 / 1 / 5 / 3;
      ${theme.below.lg} {
        grid-area: 1 / 1 / 3 / 2;
      }
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
    .position2 {
      grid-area: 1 / 3 / 3 / 5;
      ${theme.below.lg} {
        grid-area: 3 / 1 / 4 / 2;
      }
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
    .position3 {
      grid-area: 3 / 3 / 5 / 4;
      ${theme.below.lg} {
        grid-area: 3 / 1 / 4 / 2;
      }
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
    .position4 {
      grid-area: 3 / 4 / 5 / 5;
      ${theme.below.lg} {
        grid-area: 3 / 1 / 4 / 2;
      }
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
  }

  &.layout3 {
    .position1 {
      grid-area: 1 / 1 / 5 / 3;
      ${theme.below.lg} {
        grid-area: 1 / 1 / 3 / 2;
      }
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
    .position2 {
      grid-area: 1 / 3 / 3 / 5;
      ${theme.below.lg} {
        grid-area: 3 / 1 / 4 / 2;
      }
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
    .position3 {
      grid-area: 3 / 3 / 5 / 5;
      ${theme.below.lg} {
        grid-area: 4 / 1 / 5 / 2;
      }
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
  }

  &.layout2 {
    .position1 {
      grid-area: 1 / 1 / 5 / 3;
      ${theme.below.lg} {
        grid-area: 1 / 1 / 3 / 2;
      }
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
    .position2 {
      grid-area: 1 / 3 / 5 / 5;
      ${theme.below.lg} {
        grid-area: 3 / 1 / 4 / 2;
      }
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
    ${theme.below.lg} {
      grid-template-rows: repeat(3, 1fr);
      height: 200vw;
      max-height: 200vw;
    }
  }

  &.layout1 {
    .position1 {
      grid-area: 1 / 1 / 5 / 5;
      ${theme.below.lg} {
        grid-area: 1 / 1 / 3 / 2;
      }
      [data-flight-image-container] {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
    ${theme.below.lg} {
      grid-template-rows: 1fr;
      height: 142vw;
      max-height: 142vw;
    }
  }
`;

export const BoxRow = ({ title, children }) => {
  return (
    <BoxRowWrapper>
      {title?.value && <h1>{title?.value}</h1>}
      <BoxRowItemsWrapper className={cx('layout' + children?.length)}>
        {children}
      </BoxRowItemsWrapper>
    </BoxRowWrapper>
  );
};
