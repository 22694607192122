import { ModalTrigger as BaseModalTrigger } from '@jetshop/ui/Modal/ModalTrigger';
import React from 'react';
import { css, cx } from 'linaria';

const modalStyles = css`
  left: 50%;
  top: 50%;
  background: transparent;
  padding: 0;
  outline: none;
  z-index: 9999;
  height: fit-content;
  transform: translateX(-50%) translateY(-50%);
  position: fixed;
  width: 32em;
  max-width: 100%;
  min-height: 20em;

  @media screen and (max-width: 50em) {
    padding: 2rem;
  }
`;

export default function ModalTrigger({ customStyles = ``, ...rest }) {
  return (
    <BaseModalTrigger modalStyles={cx(modalStyles, customStyles)} {...rest} />
  );
}
