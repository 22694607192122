import React, { useEffect, useState, useMemo } from 'react';
import { styled } from 'linaria/react';
import { LoadingLine } from '@jetshop/ui/Loading/LoadingLine';
import { contentEditorComponents } from '../ContentEditor/ContentEditorComponents';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import fetch from 'node-fetch';
import { useLocation } from 'react-router-dom';
const StorePageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
`;

function StorePages(props) {
  const [pages, setPages] = useState(null);
  const [page, setPage] = useState(null);
  let { pathname } = useLocation();

  const key = 'Yy6SzcWDqyk4S49I9o_hNDuyI9iNy8Og';
  const baseUrl = useMemo(() => {
    if (process.env.NODE_ENV === 'development' && false) {
      return 'http://localhost:8000';
    } else {
      return 'https://inviiv.io';
    }
  }, []);

  useEffect(() => {
    if (key) {
      const storeSlug = pathname.split('/')[1];

      fetch(
        `${baseUrl}/api/content/pages/?key=${key}&page=${props?.match?.params?.slug}&store=${storeSlug}`
      )
        .then((res) => res.json())
        .then((data) => {
          setPages(data);

          if (data?.length > 0 && props?.match?.params?.slug) {
            const findPage = data?.find(
              (p) => p?.slug === props?.match?.params?.slug
            );
            setPage(findPage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props?.match?.params?.slug, props?.match?.params?.store, baseUrl]);

  return (
    <>
      {page ? (
        <>
          <StorePageWrapper>
            <ContentRenderer
              items={page?.parsed_content?.items}
              components={contentEditorComponents}
            />
          </StorePageWrapper>
        </>
      ) : (
        <LoadingLine />
      )}
    </>
  );
}

export default StorePages;
