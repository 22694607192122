import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/';
import t from '@jetshop/intl';

import { theme } from '../Theme';

const Container = styled('div')`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background: #fff;
`;

const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding: 24px;

  ${theme.below.md} {
    padding: 12px;
  }

  h3 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;

    font-feature-settings: 'tnum' on, 'onum' on;

    margin-bottom: 8px;
    color: #ff7d20;

    ${theme.below.md} {
      font-size: 24px;
    }
  }

  p {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    letter-spacing: 0.2px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'onum' on;

    margin-left: 2px;
    color: #fff;

    ${theme.below.md} {
      font-size: 12px;
    }
  }
`;

const CampaignContent = ({ img, price, ordinaryPrice }) => (
  <BackgroundImage src={img} fillAvailableSpace>
    <h3>{price}:-</h3>
    <p>
      {t('Ord. price')}
      {` ${ordinaryPrice}:-`}
    </p>
  </BackgroundImage>
);

const CampaignBox = ({ url, ...props }) => {
  return (
    <Container>
      {url ? (
        <Link to={url}>
          <CampaignContent {...props} />
        </Link>
      ) : (
        <CampaignContent {...props} />
      )}
    </Container>
  );
};
export default CampaignBox;
