import React from 'react';
import { styled } from 'linaria/react';
import { Field, ErrorMessage } from 'formik';
import { InputError, InputPositive } from './InputStatus';

import { theme } from '../Theme';

const Input = styled('input')`
  height: 46px;
  width: 100%;
  padding: 12px 40px 12px 16px;
  margin-bottom: 0.25rem;

  background: #f7f7f7;
  border: 0;
  border-radius: 4px;
  font-weight: 400;
  font-size: ${theme.fontSizes[0]};
  font-family: ${theme.fonts.primary};
  &:disabled {
    background: #ffffff;
    color: #808080;
  }
`;

const Textarea = styled('textarea')`
  width: 100%;
  height: 150px;
  padding: 12px 40px 12px 16px;
  margin-bottom: 0.25rem;

  background: #f7f7f7;
  border: 0;
  border-radius: 4px;
  font-weight: 400;
  font-size: ${theme.fontSizes[0]};
  font-family: ${theme.fonts.primary};

  resize: none;
  &:disabled {
    background: #ffffff;
    color: #808080;
  }
`;

const Wrapper = styled('div')`
  margin-bottom: 1.5rem;
`;

const ErrorSpan = styled('span')`
  font-style: italic;
  font-size: 12px;
  color: #ff0000;
  position: absolute;
`;

const Label = styled('label')`
  font-family: ${theme.fonts.primary};
  font-size: 12px;
  color: #828282;
`;

const InputWrapper = styled('div')`
  margin-top: 4px;
`;

export const TextareaWithLabel = ({
  label,
  disabled,
  error,
  success,
  warning,
  loading,
  name,
  ...props
}) => (
  <Wrapper className="textarea-wrapper">
    <Label disabled={disabled} htmlFor={name}>
      {label}
    </Label>
    <Field
      disabled={disabled}
      id={name}
      name={name}
      render={({ field, form: { touched, errors } }) => (
        <InputWrapper>
          <Textarea {...props} {...field} />
          {touched[field.name] && errors[field.name] && <InputError />}
          {touched[field.name] && !errors[field.name] && <InputPositive />}
        </InputWrapper>
      )}
    />
    <ErrorMessage name={name} component={ErrorSpan} />
  </Wrapper>
);

const InputWithLabel = ({
  label,
  disabled,
  error,
  success,
  warning,
  loading,
  name,
  ...props
}) => (
  <Wrapper className="input-wrapper">
    <Label disabled={disabled} htmlFor={name}>
      {label}
    </Label>
    <Field
      disabled={disabled}
      id={name}
      name={name}
      render={({ field, form: { touched, errors } }) => (
        <InputWrapper>
          <Input {...props} {...field} />
          {touched[field.name] && errors[field.name] && <InputError />}
          {touched[field.name] && !errors[field.name] && <InputPositive />}
        </InputWrapper>
      )}
    />
    <ErrorMessage name={name} component={ErrorSpan} />
  </Wrapper>
);

export default InputWithLabel;
