import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import ReactModal from 'react-modal';
import { ReactComponent as Cross } from '../../svg/Cross.svg';
import Video from './Video';
import Image from '@jetshop/ui/Image/Image';
import MaxWidth from '../Layout/MaxWidth';
import Play from '../../assets/Play.png';
import { theme } from '../Theme';

const PreviviewImageWrapper = styled('div')`
  img {
    cursor: pointer;
  }
`;

const videoModal = css`
  position: fixed;
  max-width: 800px;
  width: 85%;
  height: auto;
  top: 50%;
  left: 50%;
  background: black;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);

  @media (max-width: 60rem) {
    @media all and (orientation: landscape) {
      width: 75%;
    }
  }

  &:focus {
    outline: none;
  }

  .close-btn-container {
    display: flex;
    position: absolute;
    top: -14px;
    right: -14px;
    padding: 5px;
    background: white;
    border-radius: 50%;
    z-index: 1;
    @media (max-width: 50rem) {
      top: 5px;
      right: 5px;
    }
    &:hover {
      cursor: pointer;
    }
  }

  svg.close-icon {
    width: 14px;
    height: 14px;
  }
`;

const overlayStyle = css`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5) !important;
`;

const ImageWrapper = styled(MaxWidth)`
  position: relative;
  .play-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 300px;
      height: 300px;
      ${theme.below.lg} {
        width: 80px;
        height: 80px;
      }
    }
  }
`;

const PreviewAndVideoPopup = ({ imageSrc, videoSrc }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className='hideInPrint'>
      <PreviviewImageWrapper
        onClick={() => {
          setOpen(true);
        }}
      >
        <ImageWrapper>
          <Image
            cover
            aspect={'16:9'}
            sizes={[1 / 4, 1 / 3, 1 / 2, 1 / 2]}
            alt={imageSrc}
            src={imageSrc}
          />
          <div className="play-container">
            <img src={Play} alt="play" />
          </div>
        </ImageWrapper>
      </PreviviewImageWrapper>
      <div style={{ padding: '10px' }}>
        <ReactModal
          overlayClassName={overlayStyle}
          className={videoModal}
          isOpen={open}
          onRequestClose={(e) => {
            setOpen(false);
          }}
        >
          <div
            className={'close-btn-container'}
            onClick={(e) => {
              setOpen(false);
            }}
          >
            <Cross className="close-icon" />
          </div>

          <Video videoSrc={videoSrc}></Video>
        </ReactModal>
      </div>
    </div>
  );
};

const VideoPopupWrapper = styled('div')`
  margin: 2.5rem 0;
`;

export const VideoPopup = ({ imageUrl, videoUrl }) => {
  return (
    <VideoPopupWrapper>
      <PreviewAndVideoPopup
        imageSrc={imageUrl?.value}
        videoSrc={videoUrl?.value}
      />
    </VideoPopupWrapper>
  );
};
