import React from 'react';
import { withRouter } from 'react-router';
import { styled } from 'linaria/react';

import { theme } from '../../Theme';
const PagesWrapper = styled('section')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 4rem;

  & > div {
    width: 50%;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li + li {
        margin-top: 5px;
      }
    }
  }

  h3 {
    color: ${theme.colors.black};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.2px;
    line-height: 2;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    color: #333333;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.2px;
    padding: 5px 0;

    label {
      cursor: pointer;
    }
  }

  ${theme.above.md} {
    margin-top: 0;
    width: 60%;
    justify-content: flex-end;
  }

  ${theme.below.sm} {
    flex-wrap: wrap;

    div {
      padding-bottom: 30px;
    }
  }

  ${theme.below.xs} {
    flex-wrap: wrap;

    div {
      width: 100%;
    }

    div + div {
      margin-top: 4rem;
    }
  }
`;

const goToPage = (e, history) => {
  e.preventDefault();
  const url = e.target.getAttribute('href');

  if (url.indexOf('http') !== -1) {
    window.open(url, '_newtab');
  } else {
    history.push({
      pathname: url,
      state: {
        type: 'page',
      },
    });
  }
};

const FooterLinks = ({ history, pageLinks }) =>
  pageLinks ? (
    <PagesWrapper>
      <div>
        <h3>{pageLinks?.footerEgenLeft?.mainHeader}</h3>
        <div
          onClick={(e) => goToPage(e, history)}
          dangerouslySetInnerHTML={{
            __html: pageLinks?.footerEgenLeft?.content,
          }}
        />
      </div>
      <div>
        <h3>{pageLinks?.footerEgenRight?.mainHeader}</h3>
        <div
          onClick={(e) => goToPage(e, history)}
          dangerouslySetInnerHTML={{
            __html: pageLinks?.footerEgenRight?.content,
          }}
        />
      </div>
      <div>
        <h3>{pageLinks?.footerLeft?.mainHeader}</h3>
        <div
          onClick={(e) => goToPage(e, history)}
          dangerouslySetInnerHTML={{ __html: pageLinks?.footerLeft?.content }}
        />
      </div>
      <div>
        <h3>{pageLinks?.footerRight?.mainHeader}</h3>
        <div
          onClick={(e) => goToPage(e, history)}
          dangerouslySetInnerHTML={{ __html: pageLinks?.footerRight?.content }}
        />
      </div>
    </PagesWrapper>
  ) : null;

export default withRouter(FooterLinks);
