import t, { Intl } from '@jetshop/intl';
import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { Formik, Form } from 'formik';
import InputWithLabel, { TextareaWithLabel } from '../ui/Input';
import Button from '../ui/Button';
import { useLocation } from 'react-router';

const Container = styled('div')`
  margin: 16px 0 24px;

  h2 {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 22.4px */
    margin: 0;
  }

  p {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }

  form {
    position: relative;
    margin-top: 16px;
    label {
      display: none;
    }
    .input-wrapper,
    .textarea-wrapper {
      margin-bottom: 0;
    }
    input,
    textarea {
      border: 1px solid #d2d2d2;
      border-radius: 0;
    }
  }
`;

const SubmitContainer = styled('div')`
  &.centerSubmit {
    text-align: center;
  }
`;

const RespondContainer = styled('div')`
  position: absolute;
  top: 100%;
  p {
    text-align: left;
    font-size: 12px;
    margin-top: 10px;
    color: #111;
    &.error {
      color: #ff0000;
    }
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 8px 125px;
  margin-top: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  text-transform: uppercase;
`;

export const ProductContactForm = ({
  centerSubmit = false,
  store,
  hideTarget,
  isModal,
  productName,
  email,
}) => {
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const location = useLocation();

  function validation(values) {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (!values[key]) errors[key] = 'Required';
    });
    return errors;
  }

  function onSubmit(values, { setSubmitting }) {
    setIsError(false);
    setIsSuccess(false);
    console.log({ values });
    fetch('https://r3-micros.duckdns.org/mm_con/api/submit-contact-form', {
      method: 'POST',
      'Access-Control-Allow-Origin': '*',
      Accept: 'text/plain',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ ...values, emailTo: email }),
    })
      .then((res) => res.json())
      .then(({ respond, error }) => {
        if (error.length > 0 || !respond || respond.length === 0)
          setIsError(true);
        else setIsSuccess(true);
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        console.log('finally');
      });

    setTimeout(() => {
      setSubmitting(false);
    }, 500);
  }
  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        message: '',
        subject: `Frågor om ${productName}`,
        url: location?.pathname,
      }}
      validate={validation}
      onSubmit={onSubmit}
    >
      <ContactFormInner
        isError={isError}
        isSuccess={isSuccess}
        centerSubmit={centerSubmit}
        email={email}
        hideTarget={hideTarget}
        isModal={isModal}
        subjectField
        productName={productName}
        location={location}
      />
    </Formik>
  );
};

const ContactForm = ({ centerSubmit = false, store, hideTarget, isModal }) => {
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const email =
    store?.contact?.match(/"mailto:([^"]+)"/)?.[1] ||
    'kundtjanst@mobelmastarna.se';

  function validation(values) {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (!values[key]) errors[key] = 'Required';
    });
    return errors;
  }

  function onSubmit(values, { setSubmitting }) {
    console.log({ ...values, emailTo: email });
    setIsError(false);
    setIsSuccess(false);
    console.log({ values });
    fetch('https://r3-micros.duckdns.org/mm_con/api/submit-contact-form', {
      method: 'POST',
      'Access-Control-Allow-Origin': '*',
      Accept: 'text/plain',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ ...values, emailTo: email }),
    })
      .then((res) => res.json())
      .then(({ respond, error }) => {
        console.log({ respond, error });
        if (error.length > 0 || !respond || respond.length === 0)
          setIsError(true);
        else setIsSuccess(true);
      });

    setTimeout(() => {
      setSubmitting(false);
    }, 500);
  }

  return (
    <Formik
      initialValues={{ name: '', email: '', message: '' }}
      validate={validation}
      onSubmit={onSubmit}
    >
      <ContactFormInner
        isError={isError}
        isSuccess={isSuccess}
        centerSubmit={centerSubmit}
        email={email}
        hideTarget={hideTarget}
        isModal={isModal}
      />
    </Formik>
  );
};

const ContactFormInner = ({
  isError,
  isSuccess,
  centerSubmit,
  email,
  hideTarget,
  isModal,
  productName = '[produkt saknas]',
  subjectField = false,
  location,
}) => (
  <Container>
    <h2>{t('Contact form')}</h2>
    {!isSuccess && (
      <>
        <p>{t("Please fill out the form and we'll assist you.")}</p>
        <Intl>
          {(t) => (
            <Form>
              <InputWithLabel
                type="text"
                name="name"
                label={t('Name')}
                placeholder={t('Name')}
                required
              />

              <input
                className="url"
                type="hidden"
                name="url"
                label="Url"
                required
                disabled
              />

              <input
                className="subject"
                type="hidden"
                name="subject"
                label="Ämne"
                required
                disabled
              />

              <InputWithLabel
                type="email"
                name="email"
                label={t('Email')}
                placeholder={t('E-mail')}
                required
              />
              <TextareaWithLabel
                type="text"
                name="message"
                label={t('Message')}
                placeholder={t('Message')}
                required
              />
              <SubmitContainer
                centerSubmit={centerSubmit}
                className={centerSubmit && 'centerSubmit'}
              >
                <StyledButton type="submit">{t('Send')}</StyledButton>
              </SubmitContainer>
              {(isError || isSuccess) &&
                (console.log('error', isError),
                (
                  <RespondContainer>
                    {isError && (
                      <p className="error">
                        {t(
                          'There was an error with sending your message. Please contact us on {email}.',
                          { email }
                        )}
                      </p>
                    )}
                  </RespondContainer>
                ))}
            </Form>
          )}
        </Intl>
      </>
    )}
    {isSuccess && (
      <>
        <p className="success" style={{ marginBottom: '1rem' }}>
          {t('Your message has been sent. We will get back to you shortly.')}
        </p>
        {isModal && (
          <StyledButton onClick={hideTarget} type="button">
            {t('Close')}
          </StyledButton>
        )}
      </>
    )}
  </Container>
);

export default ContactForm;
