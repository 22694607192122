import t from '@jetshop/intl';
import React, { useState, useEffect } from 'react';
import { styled } from 'linaria/react';
import Carousel, { generateDots } from 'my-react-carousel';
import { ReactComponent as Arrow } from '../../svg/Arrow.svg';
import { Above } from '@jetshop/ui/Breakpoints';

import MaxWidth from '../Layout/MaxWidth';

import { theme } from '../Theme';

const StyledMaxWidth = styled(MaxWidth)`
  ${theme.below.md} {
    padding: 0;
  }
`;

const Container = styled('div')`
  width: 100%;
  position: relative;

  .arrow-container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      cursor: pointer;
      opacity: 0.8;
      height: 30px;
      width: 35px;

      path {
        stroke: ${theme.colors.white};
      }
    }

    &.prev {
      left: 0;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.15), transparent);

      svg {
        transform: rotate(90deg);
        margin-left: -5px;
      }
    }

    &.next {
      right: 0;
      background: linear-gradient(-90deg, rgba(0, 0, 0, 0.15), transparent);

      svg {
        transform: rotate(-90deg);
        margin-right: -5px;
      }
    }
  }
`;

const SingleDot = styled('button')`
  position: relative;
  padding: 10px;
  background: transparent;

  &::after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${theme.colors.white};
    opacity: ${({ isActive }) => (isActive ? '1' : '0.5')};
    box-shadow: ${({ isActive }) =>
      isActive ? '0 0 15px rgba(0,0,0,0.5)' : '0 0 5px black, 0 0 10px black'};
  }

  ${theme.below.md} {
    padding: 10px 25px;
    &::after {
      left: 20px;
    }
  }
`;

const DotsContainer = styled('div')`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
`;

const MegaGallery = ({ children }) => (
  <StyledMaxWidth>
    <Container>
      <Carousel
        slidesToShow={1}
        render={generateDots((carouselProps) => (
          <CarouselContent {...carouselProps} />
        ))}
      >
        {children}
      </Carousel>
    </Container>
  </StyledMaxWidth>
);

const CarouselContent = ({ slides, dots, previous, next }) => {
  const [isAutoPlay, setIsAutoPlay] = useState(true);

  useEffect(() => {
    const autoPlay = setInterval(() => {
      next();
    }, 3000);

    if (!isAutoPlay) clearInterval(autoPlay);
    return () => clearInterval(autoPlay);
  }, [next, isAutoPlay]);

  return (
    <>
      {slides}
      <DotsContainer>
        {dots.map(({ index, isActive, onClick }) => (
          <SingleDot
            key={index}
            isActive={isActive}
            onClick={() => {
              setIsAutoPlay(false);
              onClick();
            }}
            aria-label={t('Go to campaign') + ` ${index}`}
          />
        ))}
      </DotsContainer>
      <Above breakpoint="md">
        <>
          <div className="arrow-container prev">
            <Arrow
              onClick={() => {
                setIsAutoPlay(false);
                previous();
              }}
            />
          </div>
          <div className="arrow-container next">
            <Arrow
              onClick={() => {
                setIsAutoPlay(false);
                next();
              }}
            />
          </div>
        </>
      </Above>
    </>
  );
};

export default MegaGallery;
