import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import uspQuery from './components/ProductPage/UspQuery.gql';
import { theme } from './components/Theme';
import createFacebookTracker from '@jetshop/core/server/tracking/facebook';
import { addwishTracker } from '@jetshop/flight-addwish';

const shopConfig = {
  theme,
  apolloConfig: {
    shopid: 'mobelmastarna', // process.env.REACT_APP_SHOP_ID || 'mobelmastarna-test',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: [
      {
        query: uspQuery,
        variables: { id: 255 },
      },
    ],
  },
  useTrackingConsentAPI: true,
  nostoAccountID: process.env.REACT_APP_NOSTO_ACCOUNT_ID,
  addwishAccountID: process.env.REACT_APP_ADDWISH_ACCOUNT_ID || '',
  trackingID: process.env.REACT_APP_GA_TRACKING_ID || 'UA-152940464-1',
  trackers: [addwishTracker('')],
  serverTrackers: [
    createFacebookTracker({
      pixelId: process.env.FACEBOOK_PIXEL_ID,
      token: process.env.FACEBOOK_CAPI_TOKEN,
    }),
  ],

  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID || 'GTM-MJSQFD',
  useArticleNumberAsId: true,
  relewareEnabled: false,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {},
  },
  channelOverrides: {},
  disableGeoRedirect: false,
  singleDomainMode: false,
  schemaExtensions: [],
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  deprecations: {
    useOldProductVariants: false,
    useOldFilterAPI: false,
  },
  resendApiKey:
    process.env.RESEND_API_KEY || 're_LEFqWvZ2_FdXT3JPWWcmG29rAsykPPkgj',
  sendgridApiKey:
    process.env.SENDGRID_API_KEY ||
    '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',
};

export default shopConfig;
