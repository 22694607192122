import React, { Component } from 'react';
import { styled } from 'linaria/react';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { ReactComponent as Carrot } from '../../../../svg/Carrot.svg';
import t from '@jetshop/intl';
import { AccordionContent } from '../../../ProductPage/AccordionContent';

import { theme } from '../../../Theme';

const CategoryTree = styled('ul')`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-left: 0;
`;

const SubCategories = styled(CategoryTree)``;

const MainCategoryWrapper = styled('div')`
  .main-category-link {
    text-transform: initial;

    span {
      text-transform: lowercase;
    }
  }
`;

const RootCategory = styled('li')`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.colors.notWhite};
  min-height: 57px;
`;

const SubCategory = styled('li')`
  font-size: 0.875rem;
`;

const SubSubCategory = styled('li')`
  font-size: 0.75rem;
`;

const StyledCategory = styled(CategoryLink)`
  padding: 1rem 0;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: ${theme.fontSizes[2]};
  line-height: 1.6;
  color: #111;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.main-category-link {
    justify-content: flex-start;
  }

  &.main-subcategory-link {
    justify-content: flex-start;
  }

  div {
    display: none;
  }

  &.with-children {
    div {
      display: block;
    }
  }
`;

const StyledSubCategory = styled(StyledCategory)`
  font-size: ${theme.fontSizes[2]};
  text-transform: capitalize;
  border-bottom: none;
  padding: 0.5rem 0;

  display: flex;

  &:active,
  &:hover,
  &:focus {
    background: ${theme.colors.notWhite};
    border-radius: 2px;
  }
`;

const StyledSubSubCategory = styled(StyledSubCategory)`
  font-size: ${theme.fontSizes[2]};
  text-transform: capitalize;
  border-bottom: none;
  padding: 0.5rem 0.75rem;
  display: flex;
  font-weight: 400;

  &:active,
  &:hover,
  &:focus {
    background: ${theme.colors.notWhite};
    border-radius: 2px;
  }
`;

const SvgWrapper = styled('div')`
  width: 15px;
  transition: transform 0.3s;

  svg {
    use {
      fill: #111;
    }
  }
  transform: rotate(${({ open }) => (open ? '-180deg' : '0deg')});
`;

export default class MobileCategories extends Component {
  renderSubCategories = (categories) => {
    if (!categories || !categories.length) return null;

    const { closeMenu } = this.props;

    return (
      <SubCategories>
        <Accordion>
          {({ openIndexes, handleClick }) =>
            categories.map((subCategory, index) => {
              const open = openIndexes.includes(index);

              return (
                <>
                  <SubCategory key={subCategory.id}>
                    <StyledSubCategory
                      category={subCategory}
                      onClick={(e) =>
                        this.handleClick(
                          e,
                          subCategory.subcategories.length,
                          index,
                          handleClick
                        )
                      }
                      className={
                        subCategory.subcategories.length > 0
                          ? 'with-children'
                          : null
                      }
                    >
                      <span>{subCategory.name}</span>
                      {subCategory.subcategories.length > 0 && (
                        <SvgWrapper open={open}>
                          <Carrot />
                        </SvgWrapper>
                      )}
                    </StyledSubCategory>

                    {subCategory.subcategories.length > 0 && (
                      <AccordionContent isOpen={open}>
                        <StyledSubCategory
                          className="main-subcategory-link"
                          category={subCategory}
                          onClick={() => closeMenu()}
                        >
                          {t('All within ')} &nbsp;{' '}
                          <span>{subCategory.name}</span>
                        </StyledSubCategory>
                        {this.renderSubSubCategories(subCategory.subcategories)}
                      </AccordionContent>
                    )}
                  </SubCategory>
                </>
              );
            })
          }
        </Accordion>
      </SubCategories>
    );
  };

  renderSubSubCategories = (subcategories) => {
    const { closeMenu } = this.props;

    return (
      <SubCategories>
        {subcategories.map((subSubCategory) => {
          return (
            <SubSubCategory key={subSubCategory.id}>
              <StyledSubSubCategory
                category={subSubCategory}
                onClick={() => closeMenu()}
              />
            </SubSubCategory>
          );
        })}
      </SubCategories>
    );
  };

  handleClick = (e, hasChildren, index, handleAccordionClick) => {
    const { closeMenu } = this.props;

    if (hasChildren) {
      e.stopPropagation();
      e.preventDefault();
    } else {
      closeMenu();
    }

    handleAccordionClick(index);
  };

  render() {
    const { categories, closeMenu, categoryIndex } = this.props;

    if (categories && categories.length > 0) {
      return (
        <CategoryTree root>
          <Accordion single initialOpenIndexes={[categoryIndex]}>
            {({ openIndexes, handleClick }) =>
              categories.map((category, i) => {
                let classNames = [];
                category.subcategories.length &&
                  classNames.push('with-children');
                const open = openIndexes.includes(i);
                open && classNames.push('open');

                return (
                  <RootCategory key={category.id}>
                    <StyledCategory
                      open={open}
                      category={category}
                      onClick={(e) =>
                        this.handleClick(
                          e,
                          category.subcategories.length,
                          i,
                          handleClick
                        )
                      }
                      className={
                        classNames.length > 0 ? classNames.join(' ') : null
                      }
                    >
                      <span>{category.name}</span>
                      <SvgWrapper open={open}>
                        <Carrot />
                      </SvgWrapper>
                    </StyledCategory>

                    <AccordionContent isOpen={open}>
                      <MainCategoryWrapper>
                        <StyledSubCategory
                          className="main-category-link"
                          category={category}
                          onClick={() => closeMenu()}
                        >
                          {t('All within ')} &nbsp; <span>{category.name}</span>
                        </StyledSubCategory>
                      </MainCategoryWrapper>
                      {this.renderSubCategories(category.subcategories)}
                    </AccordionContent>
                  </RootCategory>
                );
              })
            }
          </Accordion>
        </CategoryTree>
      );
    } else return null;
  }
}
